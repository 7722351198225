import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SortType, SortOrder } from '../../../enums';

interface IProps {
  title: string,
  iconAsc: IconDefinition, 
  iconDesc: IconDefinition,
  type: SortType,
  active: SortType,
  order: SortOrder,
  defaultOrder: SortOrder,
  clicked: (sort: SortType, sortOrder: SortOrder) => void
}

const sortItem = ({title, iconAsc, iconDesc, type, active, order, clicked, defaultOrder}:IProps) => {

  let icon = defaultOrder === SortOrder.ASC ? iconAsc : iconDesc;
  let color = null;

  if(active === type) {
    color = '#003973';
    if(order === SortOrder.DESC) {
      icon = iconDesc;
    } else if(order === SortOrder.ASC) {
      icon = iconAsc;
    }
  }

  const onSortItemClicked = () => {
    let updatedSortOrder = defaultOrder;
    if(active === type) {
      updatedSortOrder = order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    clicked(type, updatedSortOrder);
  }
  
  return (
    <div onClick={onSortItemClicked}>
      <label>{title}</label>
      <FontAwesomeIcon icon={icon} size="2x" color={color}/>
    </div>
  )
}

export default sortItem;
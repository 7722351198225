import React from 'react';

const ProductScoresContext = React.createContext<{
  showProductScores: boolean;
  setShowProductScores: (showProductScores: React.SetStateAction<boolean>) => void;
}>({
  showProductScores: false,
  setShowProductScores: (showProductScores) => {}
});

export default ProductScoresContext;
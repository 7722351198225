import React from 'react';
import { Row, Col, Progress } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const classes = require('./ContentBox.module.scss');

interface ContentBoxProps {
    size?: string,
    title: string | JSX.Element | null,
    loading?: boolean,
    children: Array<JSX.Element> | JSX.Element | string,
    hideHr?: boolean,
    customActions?: Array<any>,
    actions?: Array<any>
}

const contentBox = ({ size, title, loading, children, hideHr, customActions, actions }: ContentBoxProps) => {

    if(loading) {
        title = 'Loading...';
        children = <Progress animated color="primary" value="100" />;
    }

    if(size === 'h5') {
        title = <h5>{title}</h5>;
    } else {
        title = <h1>{title}</h1>;
    }


    return (
        <Row>
            <Col>
                <div className="content-box">
                    <div className={classes.Header}>
                        {title}
                        <div className={classes.Actions}>
                            {
                                customActions && customActions.map((action, index) => (
                                    <span key={index}>
                                        {action}
                                    </span>
                                ))
                            }
                            {
                                actions && actions.map((action, index) => (
                                    <NavLink key={index} to={action.link}>
                                        <FontAwesomeIcon size="2x" icon={action.icon} />
                                    </NavLink>
                                ))
                            }
                        </div>
                    </div>
                    {hideHr ? null : <hr/>}
                    {children}
                </div>
            </Col>
        </Row>
    );
} 


export default contentBox;
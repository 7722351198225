import React from 'react';

import { NavLink } from 'react-router-dom'

const classes = require('./MenuItem.module.scss');

interface IProps {
    children: string,
    link: string,
    onClick: () => void
}

const menuItem = ({children, link, onClick}:IProps) => (
    <li className={classes.MenuItem}>
        <NavLink to={link} onClick={onClick}>
            {children}
        </NavLink>
    </li>
)

export default menuItem;
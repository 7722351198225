import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Alert, Breadcrumb, BreadcrumbItem, Button, Form } from "reactstrap";

import UserProjects from "../../../components/UserProjects/UserProjects";
import ContentBox from "../../../hoc/ContentBox/ContentBox";
import { IAppState, IUser } from "../../../interfaces";
import {
  controlsToFormGroups,
  getFormData,
  initForm,
  validateInput
} from "../../../shared/utility";
import * as actions from "../../../store/actions";

interface IStateProps {
  currentUser: IUser | null;
  user: IUser | null;
  error: string | null;
  loading: boolean;
  success: boolean;
}

interface IDispatchProps {
  onGetUser: (id: string) => {};
  onUpdateUser: (id: string, formData: {}) => {};
  onSaveUser: (formData: {}) => {};
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatchProps> {}

const user = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      firstName: {
        elementType: "input",
        elementConfig: {
          label: "First name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          label: "Last name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      email: {
        elementType: "input",
        elementConfig: {
          label: "Email",
          type: "email"
        },
        value: "",
        validation: {
          required: true,
          email: true
        },
        valid: false
      },
      phoneNumber: {
        elementType: "input",
        elementConfig: {
          label: "Phone number",
          type: "text"
        },
        value: "",
        validation: {},
        valid: true
      },
      password: {
        elementType: "input",
        elementConfig: {
          label: "Password",
          type: "password"
        },
        value: "",
        validation: {
          password: true,
          required: true,
          match: "confirmPassword",
          minLength: 8
        },
        valid: false
      },
      confirmPassword: {
        elementType: "input",
        elementConfig: {
          label: "Password again",
          type: "password"
        },
        value: "",
        validation: {
          password: true,
          required: true,
          match: "password",
          minLength: 8
        },
        valid: false
      },
      allowLogin: {
        elementType: "checkbox",
        elementConfig: {
          label: null,
          type: "checkbox",
          options: [{ value: true, displayValue: "Allow login" }]
        },
        validation: {},
        value: false,
        valid: true
      },
      role: {
        elementType: "radio",
        elementConfig: {
          label: "Role",
          type: "radio",
          options: [
            { value: "ADMIN", displayValue: "Admin" },
            { value: "USER", displayValue: "User" }
          ],
          validation: {
            required: true
          },
          value: "",
          valid: false
        }
      },
      status: {
        elementType: "radio",
        elementConfig: {
          label: "Status",
          type: "radio",
          options: [
            { value: "PENDING", displayValue: "Pending" },
            { value: "ACTIVE", displayValue: "Active" }
          ],
          validation: {
            required: true
          },
          value: "",
          valid: false
        }
      }
    },
    formIsValid: false
  });

  useEffect(() => {
    if (props.match.params.id !== "add") {
      props.onGetUser(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.user) {
      const data = initForm({ ...state.controls }, props.user);
      setState({
        controls: data.controls,
        formIsValid: data.formIsValid
      });
    }
  }, [props.user]);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { user, onUpdateUser, onSaveUser, history } = props;

    const id = user ? user.id : undefined;
    const formData = getFormData(state.controls);
    if (id) {
      await onUpdateUser(id, formData);
    } else {
      await onSaveUser(formData);
    }
    history.push(`/users`);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = validateInput(
      state.controls,
      controlName,
      event.target.value
    );

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  const { error, user, loading, match } = props;

  let title = null;
  if (loading) {
    title = "Loading...";
  } else if (user !== null) {
    title = user.label;
  } else if (match.params.id === "add") {
    title = "New user";
  }

  return (
    <React.Fragment>
      {error && <Alert color="danger">{error}</Alert>}
      <Breadcrumb>
        <BreadcrumbItem>
          <NavLink to="/users">Users</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>{title}</BreadcrumbItem>
      </Breadcrumb>
      <ContentBox title={title} loading={loading}>
        <Form onSubmit={submitHandler}>
          {controlsToFormGroups(state.controls, inputChangedHandler)}
          <UserProjects projects={user ? user.projects : null} />
          <Button type="submit" color="primary" disabled={!state.formIsValid}>
            Submit
          </Button>
        </Form>
      </ContentBox>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    user: state.users.user,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetUser: id => dispatch(actions.getUser(id)),
    onSaveUser: user => dispatch(actions.saveUser(user)),
    onUpdateUser: (id, user) => dispatch(actions.updateUser(id, user))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(user);

import qs from "qs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Alert, Button, Col, Form, Progress, Row } from "reactstrap";

import { controlsToFormGroups, validateInput } from "../../shared/utility";
import * as actions from "../../store/actions";
import { IAppState } from "../../interfaces";

const classes = require("./Auth.module.css");

interface IStateProps {
  redirect: string;
  isAuthenticated: boolean;
  error: string;
  loading: boolean;
}

interface IDispatchProps {
  onAuth: (account: string, password: string) => {};
  onClearError: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const auth = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      account: {
        elementType: "input",
        elementConfig: {
          label: "Email",
          type: "email"
        },
        validation: {
          required: true,
          email: true
        },
        valid: false,
        value: ""
      },
      password: {
        elementType: "input",
        elementConfig: {
          label: "Password",
          type: "password"
        },
        validation: {
          password: true,
          required: true,
          minLength: 8
        },
        valid: false,
        value: ""
      }
    },
    controlsIsValid: false
  });

  useEffect(() => {
    const email = qs.parse(props.location.search, { ignoreQueryPrefix: true })
      .email;
    if (email) {
      inputChangedHandler(email, "account");
    }
  }, []);

  useEffect(() => {
    const { isAuthenticated, redirect, history } = props;
    if (isAuthenticated) {
      if (redirect) {
        history.push(redirect);
      } else {
        history.push("/projects");
      }
    }
  }, [props.isAuthenticated]);

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    let value: any = event;
    if (event.target) {
      value = event.target.value;
    }

    const validation = validateInput(state.controls, controlName, value);

    setState({
      controls: validation.controls,
      controlsIsValid: validation.formIsValid
    });
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { account, password } = state.controls;
    const { onAuth } = props;

    await onAuth(account.value, password.value);
  };

  const { error, loading } = props;

  return (
    <Row>
      <Col sm="12" md={{ size: 4, offset: 4 }}>
        <div className={`content-box ${classes.Content}`}>
          <h5>Log in</h5>
          <hr />
          {(loading && <Progress animated color="primary" value="100" />) || (
            <Form onSubmit={submitHandler}>
              {controlsToFormGroups(state.controls, inputChangedHandler)}
              <Button type="submit" color="primary">
                Submit
              </Button>
              {error && (
                <Alert className={classes.ErrorMessage} color="danger">
                  {error}
                </Alert>
              )}
            </Form>
          )}
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.currentUser !== null,
    redirect: state.auth.redirect
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onAuth: (account: string, password: string) =>
      dispatch(actions.auth(account, password)),
    onClearError: () => dispatch(actions.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(auth);

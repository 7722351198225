import axios from '../../axios';
import { ActionTypes } from './actionTypes';
import { Dispatch } from 'redux';

import { IProjectAction, IProject } from '../../interfaces';
import { SortOrder, SortType } from '../../enums';


export const clearProjectsState = ():IProjectAction => {
    return {
        type: ActionTypes.CLEAR_PROJECTS_STATE
    };
};

export const findProjectsStart = ():IProjectAction => {
    return {
        type: ActionTypes.FIND_PROJECTS_START
    };
};

export const findProjectsSuccess = (cursor:string, projects:Array<any>, hasMore:boolean):IProjectAction => {
    return {
        type: ActionTypes.FIND_PROJECTS_SUCCESS,
        projects,
        cursor,
        hasMore
    };
};

export const findProjectsFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.FIND_PROJECTS_FAIL,
        error
    }
};

export const findProjects = (startCursor:string = '', sortType: SortType, sortOrder: SortOrder) => {
    return async (dispatch:any, getState:any) => {
        dispatch(findProjectsStart());
        try {
            const res = await axios.get(`/json/projects/list?cursor=${startCursor}&sortType=${SortType[sortType]}&sortOrder=${SortOrder[sortOrder]}`);
            const { projects, cursor, hasMore } = res.data;
            dispatch(findProjectsSuccess(cursor, projects, hasMore));
        } catch ({ response }) {
            dispatch(findProjectsFail(response.data));
        }
        
    };
};

export const getProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.GET_PROJECT_START
    };
};

export const getProjectSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.GET_PROJECT_SUCCESS,
        project
    };
};

export const getProjectFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.GET_PROJECT_FAIL,
        error
    }
};

export const getProject = (id:string) => {
    return async (dispatch:any) => {
        dispatch(getProjectStart());
        try {
            const res = await axios.get(`/json/projects/get?id=${id}`);
            const project = res.data;
            dispatch(getProjectSuccess(project));    
        } catch ({ response }) {
            dispatch(getProjectFail(response.data));
        }
        
    };
};

export const deleteProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_START
    };
};

export const deleteProjectSuccess = ():IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_SUCCESS
    };
};

export const deleteProjectFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_FAIL,
        error
    }
};

export const deleteProject = (id:string) => {
    return async (dispatch:any) => {
        dispatch(deleteProjectStart());
        try {
            await axios.delete(`/json/projects/delete?id=${id}`);
            dispatch(deleteProjectSuccess());    
        } catch ({ response }) {
            dispatch(deleteProjectFail(response.data));
        }
        
    };
};

export const deleteProjectAttachmentStart = ():IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_ATTACHMENT_START
    };
};

export const deleteProjectAttachmentSuccess = ():IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_ATTACHMENT_SUCCESS
    };
};

export const deleteProjectAttachmentFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_ATTACHMENT_FAIL,
        error
    }
};

export const deleteProjectAttachment = (projectId:string, id:string) => {
    return async (dispatch:any) => {
        dispatch(deleteProjectAttachmentStart());
        try {
            await axios.delete(`/json/projects/delete/attachment?project=${projectId}&id=${id}`);
            dispatch(deleteProjectAttachmentSuccess());    
        } catch ({ response }) {
            dispatch(deleteProjectAttachmentFail(response.data));
        }
        
    };
};

export const saveProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_START
    };
};

export const saveProjectSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_SUCCESS,
        project
    };
};

export const saveProjectFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_FAIL,
        error
    }
};

export const saveProject = (formData:any) => {
    return async (dispatch:any) => {
        dispatch(saveProjectStart());
        try {
            const res = await axios.post(`/json/projects/save`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const project = res.data;
            dispatch(saveProjectSuccess(project));    
        } catch ({ response }) {
            dispatch(saveProjectFail(response.data));
        }
        
    };
};

export const updateProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_START
    };
};

export const updateProjectSuccess = ():IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_SUCCESS
    };
};

export const updateProjectFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_FAIL,
        error
    }
};

export const updateProject = (id:string, formData:FormData) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateProjectStart());
        try {
            await axios.put(`/json/projects/update?id=${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            dispatch(updateProjectSuccess());    
        } catch ({ response }) {
            let errorMessage = response.statusText + ": " + response.data;
            dispatch(updateProjectFail(errorMessage));
        }
        
    };
};


export const shareProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.SHARE_PROJECT_START
    };
};

export const shareProjectSuccess = (project:IProject, shareErrors:string):IProjectAction => {
    return {
        type: ActionTypes.SHARE_PROJECT_SUCCESS,
        project,
        shareErrors
    };
};

export const shareProjectFail = (error:string|any, shareErrors:string|any):IProjectAction => {
    return {
        type: ActionTypes.SHARE_PROJECT_FAIL,
        error,
        shareErrors
    }
};

export const shareProject = (id:string, data:any) => {
    return async (dispatch:Dispatch) => {
        dispatch(shareProjectStart());
        try {
            const res = await axios.post(`/json/projects/share?id=${id}`, data);
            const { project, shareErrors } = res.data;

            if(project) {
                return dispatch(shareProjectSuccess(project, shareErrors));    
            }
            dispatch(shareProjectFail(null, shareErrors))
            
        } catch ({ response }) {
            dispatch(shareProjectFail(response.data, null));
        }
        
    };
};


export const unshareProjectStart = ():IProjectAction => {
    return {
        type: ActionTypes.UNSHARE_PROJECT_START
    };
};

export const unshareProjectSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.UNSHARE_PROJECT_SUCCESS,
        project
    };
};

export const unshareProjectFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.UNSHARE_PROJECT_FAIL,
        error
    }
};

export const unshareProject = (projectId:string, userId:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(unshareProjectStart());
        try {
            const res = await axios.post(`/json/projects/unshare?id=${projectId}`, {user: userId});
            const project = res.data;
            return dispatch(unshareProjectSuccess(project));    
        } catch ({ response }) {
            dispatch(unshareProjectFail(response.data));
        }
        
    };
};

export const saveProjectCommentStart = ():IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_COMMENT_START
    };
};

export const saveProjectCommentSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_COMMENT_SUCCESS,
        project
    };
};

export const saveProjectCommentFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.SAVE_PROJECT_COMMENT_FAIL,
        error
    }
};

export const saveProjectComment = (projectId:string, formData:any) => {
    return async (dispatch:Dispatch) => {
        dispatch(saveProjectCommentStart());
        try {
            const res = await axios.post(`/json/projects/comment/save?project=${projectId}`, formData);
            const project = res.data;
            return dispatch(saveProjectCommentSuccess(project));    
        } catch ({ response }) {
            dispatch(saveProjectCommentFail(response.data));
        }
    };
};

export const updateProjectCommentStart = ():IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_COMMENT_START
    };
};

export const updateProjectCommentSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_COMMENT_SUCCESS,
        project
    };
};

export const updateProjectCommentFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.UPDATE_PROJECT_COMMENT_FAIL,
        error
    }
};

export const updateProjectComment = (projectId:string, id:string, formData:any) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateProjectCommentStart());
        try {
            const res = await axios.put(`/json/projects/comment/update?project=${projectId}&id=${id}`, formData);
            const project = res.data;
            return dispatch(updateProjectCommentSuccess(project));    
        } catch ({ response }) {
            dispatch(updateProjectCommentFail(response.data));
        }
    };
};

export const deleteProjectCommentStart = ():IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_COMMENT_START
    };
};

export const deleteProjectCommentSuccess = (project:any):IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_COMMENT_SUCCESS,
        project
    };
};

export const deleteProjectCommentFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.DELETE_PROJECT_COMMENT_FAIL,
        error
    }
};

export const deleteProjectComment = (projectId:string, id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateProjectCommentStart());
        try {
            const res = await axios.delete(`/json/projects/comment/delete?project=${projectId}&id=${id}`);
            const project = res.data;
            return dispatch(deleteProjectCommentSuccess(project));    
        } catch ({ response }) {
            dispatch(deleteProjectCommentFail(response.data));
        }
    };
};

export const searchProjectsStart = ():IProjectAction => {
    return {
        type: ActionTypes.SEARCH_PROJECTS_START
    };
};

export const searchProjectsSuccess = (search:Array<string>):IProjectAction => {
    return {
        type: ActionTypes.SEARCH_PROJECTS_SUCCESS,
        search
    };
};

export const searchProjectsFail = (error:string):IProjectAction => {
    return {
        type: ActionTypes.SEARCH_PROJECTS_FAIL,
        error
    }
};

export const searchProjects = (queryString:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(searchProjectsStart());
        try {
            const res = await axios.get(`/json/projects/search?q=${queryString}`);
            dispatch(searchProjectsSuccess(res.data));
        } catch ({ response }) {
            dispatch(searchProjectsFail(response.data));
        }
        
    };
};
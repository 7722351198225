import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Alert, Button, Form } from "reactstrap";

import ContentBox from "../../hoc/ContentBox/ContentBox";
import { IUser, IAppState } from "../../interfaces";
import {
  controlsToFormGroups,
  getFormData,
  initForm,
  validateInput
} from "../../shared/utility";
import * as actions from "../../store/actions";

interface IStateProps {
  user: IUser;
  loading: boolean;
  error: string;
  success: boolean;
}

interface IDispatchProps {
  onGetActivateUser: (id: string, activateToken: string) => void;
  onActivateUser: (id: string, activateToken: string, formData: any) => void;
}

interface IMatchProps {
  id: string;
  activateToken: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatchProps> {}

const activate = (props: IProps) => {
  const [state, setState] = useState({
    controls: {
      firstName: {
        elementType: "input",
        elementConfig: {
          label: "First name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      lastName: {
        elementType: "input",
        elementConfig: {
          label: "Last name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      password: {
        elementType: "input",
        elementConfig: {
          label: "Password",
          type: "password"
        },
        value: "",
        validation: {
          password: true,
          required: true,
          match: "confirmPassword",
          minLength: 8
        },
        valid: false
      },
      confirmPassword: {
        elementType: "input",
        elementConfig: {
          label: "Password again",
          type: "password"
        },
        value: "",
        validation: {
          password: true,
          required: true,
          match: "password",
          minLength: 8
        },
        valid: false
      }
    },
    formIsValid: false
  });

  useEffect(() => {
    (async () => {
      const { id, activateToken } = props.match.params;
      await props.onGetActivateUser(id, activateToken);
      if (props.user) {
        const data = initForm({ ...state.controls }, props.user);
        setState({
          controls: data.controls,
          formIsValid: data.formIsValid
        });
      }
    })();
  }, []);

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { match, user } = props;
    const { activateToken } = match.params;

    const id = user.id;
    const formData = getFormData(state.controls);
    await props.onActivateUser(id, activateToken, formData);
    //this.props.history.push(`/`);
  };

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = validateInput(
      state.controls,
      controlName,
      event.target.value
    );

    setState({
      controls: validation.controls,
      formIsValid: validation.formIsValid
    });
  };

  const { loading, user, error, success } = props;

  return (
    <React.Fragment>
      {error && <Alert color="danger">{error}</Alert>}
      {success && (
        <Alert color="success">
          <p>Your account is now activated.</p>
          <Button
            color="primary"
            onClick={() => props.history.push(`/?email=${user.email}`)}
          >
            Login
          </Button>
        </Alert>
      )}
      {!error && !success && (
        <ContentBox
          title={user && `Activate account ${user.email}`}
          loading={loading}
        >
          <Form onSubmit={submitHandler}>
            {controlsToFormGroups(state.controls, inputChangedHandler)}
            <Button type="submit" color="primary" disabled={!state.formIsValid}>
              Activate
            </Button>
          </Form>
        </ContentBox>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    user: state.users.user,
    loading: state.users.loading,
    success: state.users.success,
    error: state.users.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetActivateUser: (id, activateToken) =>
      dispatch(actions.getActivateUser(id, activateToken)),
    onActivateUser: (id, activateToken, formData) =>
      dispatch(actions.activateUser(id, activateToken, formData))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(activate);

import React, { Component } from "react";

import { ListGroup, ListGroupItem } from "reactstrap";
import { NavLink } from "react-router-dom";

import { IProject } from "../../interfaces";

interface IProps {
  projects: Array<IProject>;
}

const userProjects = ({ projects }: IProps) => (
  <div>
    <h3>Projects</h3>
    {(projects && (
      <ListGroup style={{ marginBottom: "1rem" }}>
        {projects.map(project => (
          <ListGroupItem
            tag={NavLink}
            to={`/projects/${project.id}`}
            key={project.id}
            action
          >
            {project.name}
          </ListGroupItem>
        ))}
      </ListGroup>
    )) || <p>No projects</p>}
  </div>
);

export default React.memo(userProjects);

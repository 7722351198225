import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';

import { IAppState, IProject, IUser } from '../../../interfaces';
import { isValidEmail } from '../../../shared/utility';
import * as actions from '../../../store/actions';
import ConfirmationDialog from '../../UI/ConfirmationDialog/ConfirmationDialog';

const classes = require('./ShareDialog.module.scss');

interface IStateProps {
    search: Array<IUser>,
    error: string,
    project: IProject
}  

interface IDispatchProps {
    onSearchUsers: (queryString:string) => void,
    onUnshareProject: (projectId:string, userId:string) => void
}  

interface IProps extends IStateProps, IDispatchProps {
    onCancel: () => any,
    onOk: (value: any) => any
    loading: boolean,
    open: boolean
}

const defaultDialogState:any = {
    open: false,
    title: null,
    content: null,
    onOk: null
}

class ShareDialog extends Component<IProps> {
    state:any = {
        value: [],
        error: null,
        dialog: defaultDialogState
    }

    
    onChange = (newValue:any) => {
        this.setState({
            value: newValue
        })
    }
 
    onCreate = (newValue:string) => {
        const { value } = this.state;

        if(!isValidEmail(newValue)) {
            this.setState({
                error: "Email is not valid, try again."
            })
            return;
        }

        this.setState({
            error: null,
            value: [...value, {value: 'new_' + _.uniqueId(), label: newValue }]
        });
    }

    onLoadOptions = (queryString:string) => new Promise(async (resolve, reject) => {
        await this.props.onSearchUsers(queryString);
        if(this.props.error) {
            reject(this.props.error);
            return;
        }

        let items = [...this.props.search];

        const sharedUsers = this.props.project.sharedUsers && this.props.project.sharedUsers.slice();
        if(items && sharedUsers) {
            items = items.filter((item) => {
                return !sharedUsers.some((user) => {
                    return item.id === user.id;
                });
            });
        }

        resolve(items);
    });

    onOk = () => {
        this.props.onOk(this.state.value) 
        this.clearState();
    }

    onCancel = () => {
        this.props.onCancel();
        this.clearState();
    }

    clearState = () => {
        this.setState({
            value: [],
            error: null
        });
    }

    onUnshare = (user:IUser) => {

        const title = "Remove user from project";
        const content = `Are you sure you want to remove ${user.label} from project`;
        const onOk = async () => {
            
            await this.props.onUnshareProject(this.props.project.id, user.id);

            this.setState({
                dialog: defaultDialogState
            });
        }

        this.setState({
            dialog: {
                open: true,
                title,
                content,
                onOk
            }
        });
    }
    

    render() {
        const errorMessage = this.state.error ? (
            <div>
                <br/>
                <Alert color="danger">{this.state.error}</Alert> 
            </div>
        ) : null;


        const sharedUsers = this.props.project.sharedUsers;
        const sharedUsersEl = sharedUsers ? (
            (
                <small>
                    Shared with:
                    {
                        sharedUsers.map((user) => (
                            <div key={user.id}>
                                - <NavLink to={`/users/${user.id}`}>{user.label}</NavLink> <span onClick={() => this.onUnshare(user)}><FontAwesomeIcon className={classes.Trash} size="1x" icon={faTrash} /></span>
                            </div>
                        ))
                    }
                </small>
            )
        ) : null;


        let content = (
            <React.Fragment>
                <p>Start typing <b>email</b> and select or create new user.</p>
                <AsyncCreatableSelect
                    isMulti
                    onCreateOption={this.onCreate}
                    onChange={this.onChange}
                    loadOptions={this.onLoadOptions}
                    value={this.state.value}
                />
                {errorMessage}
                {sharedUsersEl}
            </React.Fragment>
        )

        if(this.props.loading) {
            content = <Progress animated color="primary" value="100" />;
        }

    
        return (
            <Modal isOpen={this.props.open} toggle={this.props.loading ? null : this.props.onCancel}>
                <ModalHeader toggle={this.props.loading ? null : this.props.onCancel}>Share</ModalHeader>
                <ModalBody>
                    <ConfirmationDialog 
                        open={this.state.dialog.open} 
                        title={this.state.dialog.title}
                        onOk={this.state.dialog.onOk}
                        onCancel={() => this.setState({
                            dialog: defaultDialogState
                        })}
                    >
                        {this.state.dialog.content}
                    </ConfirmationDialog>

                    {content}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onOk} disabled={this.props.loading}>Share</Button>
                    <Button color="secondary" onClick={this.onCancel} disabled={this.props.loading}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }

    
}

const mapStateToProps = (state:IAppState):IStateProps => {
    return {
        search: state.users.search,
        error: state.users.error,
        project: state.projects.project
    }
}

const mapDispatchToProps = (dispatch:any):IDispatchProps => {
    return {
        onSearchUsers: (queryString) => dispatch(actions.searchUsers(queryString)),
        onUnshareProject: (projectId, userId) => dispatch(actions.unshareProject(projectId, userId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareDialog);
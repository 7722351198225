import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "reactstrap";

import { IProject } from "../../interfaces";
import ProductView from "../Product/ProductView";

interface IProps {
  projects: Array<IProject>;
  loading: boolean;
  openProject: (id: string, openInNewTab: boolean) => void;
}

const projectsList = ({
  projects,
  loading,
  openProject,
}: IProps): JSX.Element => {
  if (!projects || projects.length == 0) {
    return loading ? null : <p>No projects</p>;
  }

  return (
    <React.Fragment>
      {projects.map((project) => (
        <div
          style={styles.Project}
          key={project.id}
          onClick={() => openProject(project.id, false)}
        >
          <h3>
            {project.name}{" "}
            <span
              onClick={(ev: React.MouseEvent<HTMLInputElement>) => {
                ev.stopPropagation();
                openProject(project.id, true);
              }}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </span>
          </h3>
          <p className="pre">{project.description || ""}</p>
          <Row>
            {project.products &&
              project.products.map((product) => (
                <ProductView
                  key={product.id}
                  product={product}
                  vat={project.vat}
                  investmentPeriod={project.investmentPeriod}
                  salesSlider={project.salesSlider}
                  hideDetails={true}
                  onUpdateAmountStep={null}
                  onUpdatePriceStep={null}
                  hideSliders={true}
                />
              ))}
          </Row>
        </div>
      ))}
    </React.Fragment>
  );
};

const styles = {
  Project: {
    margin: "1rem 0 1rem",
    cursor: "pointer",
  },
};

export default projectsList;

import { faSortAlphaDown, faSortAlphaUp, faSortNumericDown, faSortNumericUp } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

import { SortOrder, SortType } from '../../../enums';
import SortItem from './SortItem';

const classes = require('./Sort.module.scss');

interface IProps {
  order: SortOrder,
  active: SortType,
  hide: boolean,
  clicked: (sort: SortType, sortOrder: SortOrder) => void
}

const sort = ({order, active, clicked, hide}:IProps) => {
  if(hide) return null;
  return (
    <div className={classes.Container}>
        <SortItem title="Name" clicked={clicked} iconAsc={faSortAlphaDown} iconDesc={faSortAlphaUp} type={SortType.NAME} order={order} active={active} defaultOrder={SortOrder.ASC}/>
        <SortItem title="Created" clicked={clicked} iconAsc={faSortNumericDown} iconDesc={faSortNumericUp} type={SortType.CREATED} order={order} active={active} defaultOrder={SortOrder.DESC} />
    </div>
  )  
}

export default sort;
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';

import { IProject, IProduct } from '../../../interfaces';
import { defaultChartSettings } from '../../../shared/charts';

interface IProps {
  products: IProduct[];
}

interface IData {
  name: string;
  "Likelihood-of-purchase": number;
}

const ProjectChartsLikelihoodOfPurchase: React.FC<IProps> = ({ products }) => {
  if (!products) return null;

  const getData = (): Array<IData> => {
    const data: Array<IData> = [];
    products.forEach(product => {
      data.push({
        name: product.name,
        "Likelihood-of-purchase": product.likelihoodOfPurchase
      });
    });
    return data;
  };

  return (
    <ResponsiveBar
      {...defaultChartSettings}
      data={getData()}
      keys={["Likelihood-of-purchase"]}
      margin={{
        ...defaultChartSettings.margin,
        left: 70,
      }}
      indexBy="name"
      axisLeft={{
        ...defaultChartSettings.axisLeft,
        legend: "Likelihood-of-purchase",
        legendOffset: -60,
      }}
      legends={[]}
    />
  );
};

export default ProjectChartsLikelihoodOfPurchase;

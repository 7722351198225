import React, { useState } from "react";
import { connect } from "react-redux";

import AdminRender from "../../../hoc/Authorization/AdminRender";
import MenuItem from "./MenuItem/MenuItem";

const classes = require("./Menu.module.scss");

import { IUser, IAppState } from "../../../interfaces";

interface IStateProps {
  currentUser: IUser;
}

const menu = (props: IStateProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  let menuStyles: any = null;
  let toggleClasses = [classes.Toggle];
  if (menuIsOpen) {
    menuStyles = {
      visibility: "visible",
      opacity: 1
    };
    toggleClasses.push(classes.Toggle__close);
  }

  return (
    <nav className={classes.Menu}>
      <span className={toggleClasses.join(" ")} onClick={toggleMenu} />

      <div className={classes.MenuWrapper} style={menuStyles}>
        <div className={classes.User}>
          <p>{props.currentUser.name}</p>
          <hr />
        </div>
        <div className={classes.MenuItems}>
          <ul>
            {AdminRender(
              <MenuItem onClick={toggleMenu} link="/users">
                Users
              </MenuItem>,
              props.currentUser
            )}
            <MenuItem onClick={toggleMenu} link="/projects">
              Projects
            </MenuItem>
            <MenuItem onClick={toggleMenu} link="/logout">
              Log out
            </MenuItem>
          </ul>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps)(menu);

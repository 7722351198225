import React from 'react';
import Textarea from 'react-textarea-autosize';
import { CustomInput, FormGroup, Input, Label } from 'reactstrap';




interface IProps {
    formElement: any;
}

const DisplayInput:React.FC<IProps> = ({ formElement }) => {


    let value = formElement.config.value;
    if(formElement.config.displayValue){
        value = formElement.config.displayValue;
    }

    let inputElement = <Input className="pre" id={formElement.id} plaintext>{value}</Input>;

    let label = null;
    if(formElement.config.elementConfig.label !== null) {
        label = <Label for={formElement.id}>{formElement.config.elementConfig.label}</Label>;
    }

    let inputEl = (
        <FormGroup>
            {label}
            {inputElement}
        </FormGroup>    
    );


    return inputEl;
}

export default DisplayInput;
import { Marks } from "rc-slider";
import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { IProduct, ISlider } from "../../interfaces";
import ProductSlider from "../Product/ProductSlider/ProductSlider";
import ProjectChartsProfitMargin from "./ProjectChartsProfitMargin/ProjectChartsProfitMargin";
import ProjectChartsGrossProfit from "./ProjectChartsGrossProfit/ProjectChartsGrossProfit";
import ProjectChartsLikelihoodOfPurchase from "./ProjectChartsLikelihoodOfPurchase/ProjectChartsLikelihoodOfPurchase";
import ProjectChartsPreference from "./ProjectChartsPreference/ProjectChartsPreference";
import ProjectChartsWillingnessToPay from "./ProjectChartsWillingnessToPay/ProjectChartsWillingnessToPay";
import ProjectChartsIncrementalProfit from "./ProjectChartsIncrementalProfit/ProjectChartsIncrementalProfit";
import ProjectChartsRoi from "./ProjectChartsRoi/ProjectChartsRoi";
import ProjectChartsPaybackPeriod from "./ProjectChartsPaybackPeriod/ProjectChartsPaybackPeriod";

const classes = require("./ProjectCharts.module.scss");

interface IProps {
  products: IProduct[];
  salesSlider: ISlider;
  onUpdateAmountStep: (id: string, step: number) => void;
  onUpdatePriceStep: (id: string, step: number) => void;
}

enum ETabs {
  PREFERENCE,
  WILLINGNESS_TO_PAY,
  LIKELIHOOD_OF_PURCHASE,
  PROFIT_MARGIN,
  GROSS_PROFIT,
  INCREMENTAL_PROFIT,
  ROI,
  PAYBACK_PERIOD,
}

const ProjectCharts: React.FC<IProps> = ({
  products,
  salesSlider,
  onUpdateAmountStep,
  onUpdatePriceStep,
}) => {
  const [activeTab, setActiveTab] = useState(ETabs.PREFERENCE);
  const tabs = [
    {
      id: ETabs.PREFERENCE,
      name: "Preference",
    },
    {
      id: ETabs.WILLINGNESS_TO_PAY,
      name: "Willingness-to-pay",
    },
    {
      id: ETabs.LIKELIHOOD_OF_PURCHASE,
      name: "Likelihood-of-purchase",
    },
    {
      id: ETabs.PROFIT_MARGIN,
      name: "Profit margin",
    },
    {
      id: ETabs.GROSS_PROFIT,
      name: "Gross profit",
    },
    {
      id: ETabs.INCREMENTAL_PROFIT,
      name: "Incremental profit",
    },
    {
      id: ETabs.ROI,
      name: "ROI",
    },
    {
      id: ETabs.PAYBACK_PERIOD,
      name: "Payback period",
    },
    /*
    {
      id: ETabs.ROI_AND_PAYBACK_PERIOD,
      name: "ROI and payback period"
    }
    */
  ];

  const chartClassNames = [classes.Charts];

  if (products && products.length > 2) {
    chartClassNames.push(classes.ChartsLarge);
  } else if ([ETabs.GROSS_PROFIT].indexOf(activeTab) !== -1) {
    chartClassNames.push(classes.ChartsLarge);
  }

  return (
    <React.Fragment>
      <Nav tabs>
        {tabs.map((tab) => (
          <NavItem key={tab.id}>
            <NavLink
              className={[
                activeTab === tab.id ? "active" : "",
                classes.Tab,
              ].join(" ")}
              onClick={() => {
                setActiveTab(tab.id);
              }}
            >
              {tab.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <div className={classes.ChartContainer}>
        <div className={chartClassNames.join(" ")}>
          {(() => {
            switch (activeTab) {
              case ETabs.PREFERENCE:
                return <ProjectChartsPreference products={products} />;
              case ETabs.WILLINGNESS_TO_PAY:
                return <ProjectChartsWillingnessToPay products={products} />;
              case ETabs.LIKELIHOOD_OF_PURCHASE:
                return (
                  <ProjectChartsLikelihoodOfPurchase products={products} />
                );
              case ETabs.PROFIT_MARGIN:
                return <ProjectChartsProfitMargin products={products} />;
              case ETabs.GROSS_PROFIT:
                return <ProjectChartsGrossProfit products={products} />;
              case ETabs.INCREMENTAL_PROFIT:
                return <ProjectChartsIncrementalProfit products={products} />;
              case ETabs.ROI:
                return <ProjectChartsRoi products={products} />;
              case ETabs.PAYBACK_PERIOD:
                return <ProjectChartsPaybackPeriod products={products} />;
              default:
                return null;
            }
          })()}
        </div>

        {(() => {
          switch (activeTab) {
            case ETabs.PROFIT_MARGIN:
            case ETabs.GROSS_PROFIT:
            case ETabs.INCREMENTAL_PROFIT:
            case ETabs.ROI:
            case ETabs.PAYBACK_PERIOD:
              return (
                <div className={classes.Sliders}>
                  {products &&
                    products.map((product, index) => {
                      if (activeTab === ETabs.INCREMENTAL_PROFIT && index === 0)
                        return null;

                      if (index === 0) {
                        return (
                          <div key={product.id}>
                            <h5>{product.name}</h5>
                            <div className={classes.SliderContainer}>
                              <div className={classes.Title}>
                                <label>Unit Price (VAT 0%)</label>
                                <p style={{ margin: 0 }}>
                                  {
                                    product.calculations.find(
                                      (item) =>
                                        item.amountStep === 0 &&
                                        item.priceStep === 0
                                    ).retailPriceWithoutVat
                                  }{" "}
                                  €
                                </p>
                              </div>
                              <div className={classes.Title}>
                                <label>Sales (Units)</label>
                                <p>
                                  {
                                    product.calculations.find(
                                      (item) =>
                                        item.amountStep === 0 &&
                                        item.priceStep === 0
                                    ).salesAmount
                                  }{" "}
                                  pcs
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      }

                      const salesMarks: Marks = {
                        1: "+0%",
                        11: `+${salesSlider.maxPercent}%`,
                      };

                      const retialPriceMarks: Marks = {
                        1: "+0%",
                        11:
                          product.retailPriceSlider &&
                          `+${product.retailPriceSlider.maxPercent}%`,
                      };

                      return (
                        <div key={product.id}>
                          <h5>{product.name}</h5>
                          <div className={classes.SliderContainer}>
                            <div className={classes.Title}>
                              <label>Unit Price (VAT 0%)</label>
                              <p style={{ margin: 0 }}>
                                {
                                  product.calculations.find(
                                    (item) =>
                                      item.amountStep === 0 &&
                                      item.priceStep === product.priceStep - 1
                                  ).retailPriceWithoutVat
                                }{" "}
                                €
                              </p>
                            </div>
                            <div className={classes.Slider}>
                              <ProductSlider
                                value={product.priceStep}
                                marks={retialPriceMarks}
                                onChange={(step: number) =>
                                  onUpdatePriceStep(product.id, step)
                                }
                                slider={product.retailPriceSlider}
                              />
                            </div>
                          </div>
                          <div className={classes.SliderContainer}>
                            <div className={classes.Title}>
                              <label>Sales (Units)</label>
                              <p>
                                {
                                  product.calculations.find(
                                    (item) =>
                                      item.amountStep ===
                                        product.amountStep - 1 &&
                                      item.priceStep === 0
                                  ).salesAmount
                                }{" "}
                                pcs
                              </p>
                            </div>
                            <div className={classes.Slider}>
                              <ProductSlider
                                value={product.amountStep}
                                marks={salesMarks}
                                onChange={(step: number) =>
                                  onUpdateAmountStep(product.id, step)
                                }
                                slider={salesSlider}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              );

            default:
              return null;
          }
        })()}
      </div>
    </React.Fragment>
  );
};

export default ProjectCharts;

import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { IProduct } from "../../../interfaces";
import { defaultChartSettings } from "../../../shared/charts";


interface IProps {
  products: IProduct[];
}

interface IData {
  [productName: string]: number | string;
}

const ProjectChartsGrossAddedValue: React.FC<IProps> = ({ products }) => {
  if (!products) return null;


  const keys:Array<string> = [];
  products.forEach(product => {
    keys.push(product.name);
  }); 

  const getData = (): Array<IData> => {
    const data: Array<IData> = [];

    for(let i = 1; i <= 5; i++) {
      const yearObject:IData = {
        name: `${i} `
      }
      products.forEach(product => {
        const calculations = product.calculations;
        if(!calculations || !calculations.length) return null;
        yearObject[product.name] = (calculations.find(
          calculations =>
            calculations.priceStep === product.priceStep - 1 &&
            calculations.amountStep === product.amountStep - 1
        ) as any)['grossProfitWithoutVatYear' + i];
      });

      data.push(yearObject);
    }
    return data;
  };


  return (
    <ResponsiveBar
      {...defaultChartSettings}
      margin={{
          ...defaultChartSettings.margin,
          left: 120,
          right: 300,
          bottom: 80
      }}
      data={getData()}
      enableLabel={false}
      labelFormat={(tick) => `${tick} €`}
      keys={keys}
      indexBy="name"
      axisLeft={{
        ...defaultChartSettings.axisLeft,
        legend: "Gross profit",
        legendOffset: -110,
        format: (tick) => {
            return `${tick} €`;
        }
      }}
      axisBottom={{
        ...defaultChartSettings.axisBottom,
        legend: "years",
        legendOffset: 40,
        legendPosition: "middle"
      }}
    />
  );
};

export default ProjectChartsGrossAddedValue;

import { Dispatch } from 'redux';
import axios from '../../axios';
import { ActionTypes } from './actionTypes';

import { IUserAction, IUser } from '../../interfaces';

export const findUsersStart = ():IUserAction => {
    return {
        type: ActionTypes.FIND_USERS_START
    };
};

export const findUsersSuccess = (users:Array<IUser>):IUserAction => {
    return {
        type: ActionTypes.FIND_USERS_SUCCESS,
        users
    };
};

export const findUsersFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.FIND_USERS_FAIL,
        error
    }
};

export const findUsers = () => {
    return async (dispatch:Dispatch) => {
        dispatch(findUsersStart());
        try {
            const res = await axios.get("/json/users/list");
            const users = res.data;
            dispatch(findUsersSuccess(users));
        } catch ({ response }) {
            dispatch(findUsersFail(response.data));
        }
        
    };
};

export const getUserStart = ():IUserAction => {
    return {
        type: ActionTypes.GET_USER_START
    };
};

export const getUserSuccess = (user:IUser):IUserAction => {
    return {
        type: ActionTypes.GET_USER_SUCCESS,
        user
    };
};

export const getUserFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.GET_USER_FAIL,
        error
    }
};

export const getUser = (id:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(getUserStart());
        try {
            const res = await axios.get(`/json/users/get?id=${id}`);
            const user = res.data;
            dispatch(getUserSuccess(user));    
        } catch ({ response }) {
            dispatch(getUserFail(response.data));
        }
        
    };
};

export const saveUserStart = ():IUserAction => {
    return {
        type: ActionTypes.SAVE_USER_START
    };
};

export const saveUserSuccess = ():IUserAction => {
    return {
        type: ActionTypes.SAVE_USER_SUCCESS
    };
};

export const saveUserFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.SAVE_USER_FAIL,
        error
    }
};

export const saveUser = (formData:{}) => {
    return async (dispatch:Dispatch) => {
        dispatch(saveUserStart());
        try {
            await axios.post(`/json/users/save`, formData);
            dispatch(saveUserSuccess());    
        } catch ({ response }) {
            dispatch(saveUserFail(response.data));
        }
        
    };
};

export const updateUserStart = ():IUserAction => {
    return {
        type: ActionTypes.UPDATE_USER_START
    };
};

export const updateUserSuccess = ():IUserAction => {
    return {
        type: ActionTypes.UPDATE_USER_SUCCESS
    };
};

export const updateUserFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.UPDATE_USER_FAIL,
        error
    }
};

export const updateUser = (id:string, formData:{}) => {
    return async (dispatch:Dispatch) => {
        dispatch(updateUserStart());
        try {
            await axios.put(`/json/users/update?id=${id}`, formData);
            dispatch(updateUserSuccess());    
        } catch ({ response }) {
            dispatch(updateUserFail(response.data));
        }
        
    };
};


export const searchUsersStart = ():IUserAction => {
    return {
        type: ActionTypes.SEARCH_USERS_START
    };
};

export const searchUsersSuccess = (search:Array<IUser>):IUserAction => {
    return {
        type: ActionTypes.SEARCH_USERS_SUCCESS,
        search
    };
};

export const searchUsersFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.SEARCH_USERS_FAIL,
        error
    }
};

export const searchUsers = (queryString:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(searchUsersStart());
        try {
            const res = await axios.get(`/json/users/search?q=${queryString}`);
            dispatch(searchUsersSuccess(res.data));
        } catch ({ response }) {
            dispatch(searchUsersFail(response.data));
        }
        
    };
};

export const getActivateUserStart = ():IUserAction => {
    return {
        type: ActionTypes.GET_ACTIVATE_USER_START
    };
};

export const getActivateUserSuccess = (user:IUser):IUserAction => {
    return {
        type: ActionTypes.GET_ACTIVATE_USER_SUCCESS,
        user
    };
};

export const getActivateUserFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.GET_ACTIVATE_USER_FAIL,
        error
    }
};

export const getActivateUser = (id:string, activateToken:string) => {
    return async (dispatch:Dispatch) => {
        dispatch(getActivateUserStart());
        try {
            const res = await axios.get(`/json/users/activate/get?id=${id}&activateToken=${activateToken}`);
            const user = res.data;
            dispatch(getActivateUserSuccess(user));    
        } catch ({ response }) {
            dispatch(getActivateUserFail(response.data));
        }
        
    };
};

export const activateUserStart = ():IUserAction => {
    return {
        type: ActionTypes.ACTIVATE_USER_START
    };
};

export const activateUserSuccess = ():IUserAction => {
    return {
        type: ActionTypes.ACTIVATE_USER_SUCCESS
    };
};

export const activateUserFail = (error:string):IUserAction => {
    return {
        type: ActionTypes.ACTIVATE_USER_FAIL,
        error
    }
};

export const activateUser = (id:string, activateToken:string, formData:{}) => {
    return async (dispatch:Dispatch) => {
        dispatch(activateUserStart());
        try {
            await axios.put(`/json/users/activate/put?id=${id}&activateToken=${activateToken}`, formData);
            dispatch(activateUserSuccess());    
        } catch ({ response }) {
            dispatch(activateUserFail(response.data));
        }
        
    };
};
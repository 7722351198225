import { UserRole } from "../../enums";
import { IUser } from "../../interfaces";

const roleRender = (allowedRoles:Array<UserRole>) => 
    (object:any, currentUser:IUser, defaultValue:any = null) => {
        if(allowedRoles.includes(currentUser.role)) {
            return object;
        } 
        return defaultValue;
    }

export default roleRender;
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';

import { IProduct } from '../../../interfaces';
import { defaultChartSettings } from '../../../shared/charts';

interface IProps {
  products: IProduct[];
}

interface IData {
  name: string;
  "1st": number;
  "2nd": number;
}

const ProjectChartsPreference: React.FC<IProps> = ({ products }) => {
  if (!products) return null;

  const getData = (): Array<IData> => {
    const data: Array<IData> = [];
    products.forEach(product => {
      data.push({
        name: product.name,
        "1st": product.preference1,
        "2nd": product.preference2
      });
    });
    return data;
  };

  return (
    <ResponsiveBar
      {...defaultChartSettings}
      margin={{
          ...defaultChartSettings.margin,
          left: 70
      }}
      data={getData()}
      keys={["1st", "2nd"]}
      indexBy="name"
      maxValue={100}
      axisLeft={{
        ...defaultChartSettings.axisLeft,
        legend: "Preference",
        legendOffset: -60,
        format: (tick) => {
            return `${tick}%`;
        }
      }}
    />
  );
};

export default ProjectChartsPreference;

import React from 'react';
import {Row, Col, Card, CardBody, Table } from 'reactstrap';
import { IAttachment } from '../../interfaces';

interface IProps {
    attachments: Array<IAttachment>
}

const attachments = ({attachments}:IProps) => {
    if(!attachments) return null;

    const imageAttachments = attachments.filter((attachment) => attachment.contentType && attachment.contentType.indexOf('image') !== -1);
    
    let imageAttachmentsEl = null;
    if(imageAttachments) {
        imageAttachmentsEl = (
            <Row>
                {
                    imageAttachments.map((attachment) => {
                        return (
                            <Col key={attachment.id} xs="12" lg="6" sm="12" md="12">
                                <Card style={{ margin: '1rem' }}>
                                    <CardBody>
                                        <img src={attachment.link} alt={attachment.name} style={styles.image} />
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        );
    }
    const otherAttachments = attachments.filter(attachment => !attachment.contentType || attachment.contentType.indexOf('image') === -1);

    let otherAttachmentsEl = null;
    if(otherAttachments) {
        otherAttachmentsEl = (
            <div>
                <h5>Attachments</h5>
                <Table>
                    <tbody>
                        {
                            otherAttachments.map(attachment => (
                                <tr key={attachment.id}>
                                    <td><a href={attachment.link} target="_blank" rel="noopener noreferrer">{attachment.name}</a></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
            
        )
    }

    return (
        <div>
            {imageAttachmentsEl}
            {otherAttachmentsEl}
        </div>
    )
}

const styles = {
    image: {
        maxHeight: '100%',
        width: 'initial',
        maxWidth: '100%'
    }
}

export default attachments;
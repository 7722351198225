import React, { useContext, useRef, useEffect, useState } from "react";

import { Row, Col } from "reactstrap";
import DiffText from "../../UI/DiffText/DiffText";

const classes = require("./ProductScores.module.scss");

import { IProduct } from "../../../interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faCaretDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ProductScoresContext from "./ProductScoresContext";

interface IProps {
  product: IProduct;
  hide: boolean;
}

const productScores = ({ product, hide }: IProps) => {
  const containerRef = useRef<HTMLDivElement>();
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    const element = containerRef.current;
    if(element) {
      setMaxHeight(element.scrollHeight);
    }
  }, [containerRef])

  if (hide) return null;

  const { showProductScores, setShowProductScores } = useContext(ProductScoresContext);

  let scoreArray = [
    { text: "Preference (1st)", value: product.preference1 },
    { text: "Preference (2nd)", value: product.preference2 },
    {
      text: "Value profile",
      value: product.valueProfile,
      valueDiff: product.valueProfileDiff
    },
    {
      text: "Willingness-to-pay (1st)",
      value: product.willingnessToPay1Display,
      valueDiff: product.willingnessToPay1Diff,
      valueDiffPercent: product.willingnessToPay1DiffPercent
    },
    {
      text: "Willingness-to-pay (2nd)",
      value: product.willingnessToPay2Display,
      valueDiff: product.willingnessToPay2Diff,
      valueDiffPercent: product.willingnessToPay2DiffPercent
    },
    {
      text: "Likelihood-of-purchase",
      value: product.likelihoodOfPurchase,
      valueDiff: product.likelihoodOfPurchaseDiff,
      valueDiffPercent: product.likelihoodOfPurchaseDiffPercent
    }
  ];


  const containerClassNames = [classes.ScoresContainer];
  const iconClassNames = [classes.Icon];

  if (showProductScores) {
    iconClassNames.push(classes.IconOpen);
  } else {
    containerClassNames.push(classes.Hidden);
  }

  return (
    <>
    <div className={classes.ScoreToggler} onClick={() => setShowProductScores(productScore => !productScore)}>
      <label>Evaluation</label>
      <span>
        <FontAwesomeIcon icon={faChevronDown} className={iconClassNames.join(' ')} />
      </span>
    </div>
    <div className={containerClassNames.join(' ')} style={{ maxHeight }} ref={containerRef}>
      {scoreArray.map(item => {
        if(typeof item.value === 'undefined') return null;
        return (
          <div key={item.text}>
            <label>{item.text}</label>
            <p>
              {item.value}&nbsp;
              <DiffText
                valueDiff={item.valueDiff}
                valueDiffPercent={item.valueDiffPercent}
              />
            </p>
          </div>
        );
      })}
    </div>
    </>
  );
};

export default productScores;

import React from "react";
import { ISlider } from "../../../interfaces";

import Slider, { Marks, createSliderWithTooltip, Handle } from "rc-slider";

const SliderWithTooltip = createSliderWithTooltip(Slider);

import Tooltip from "rc-tooltip";

const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

interface IProps {
  value: number;
  onChange: (step: number) => void;
  marks: Marks;
  slider: ISlider;
}

const ProductSlider: React.FC<IProps> = ({
  value,
  onChange,
  marks,
  slider
}) => {
  
  return (
    <SliderWithTooltip
      value={value}
      onChange={onChange}
      min={1}
      max={11}
      handle={handle}
      marks={marks}
      tipFormatter={(value: number) => {
        return `+${slider.steps[value]}%`;
      }}
    />
  );
};

export default ProductSlider;

import React from "react";

import Input from "../components/UI/Input/Input";
import sanitizeHtml from "sanitize-html";
import { IUser } from "../interfaces";
import DisplayInput from "../components/UI/DisplayInput/DisplayInput";

export function updateObject<T>(oldObject: T, updatedProperties: T): T {
  return Object.assign({}, oldObject, updatedProperties);
}

export const checkValidity = (
  value: any,
  rules: any,
  controls: any
): boolean => {
  let isValid = true;

  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = String(value).trim() !== "" && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.email) {
    isValid = isValidEmail(value) && isValid;
  }

  if (rules.match) {
    isValid = controls[rules.match].value === value && isValid;
    controls[rules.match].valid = isValid;
  }

  return isValid;
};

export const isValidEmail = (email: string): boolean => {
  return /\S+@\S+\.\S+/.test(email);
};

export const getAttachmentLink = (attachment: any): string => {
  let imageLink;
  if (attachment) {
    try {
      imageLink = attachment.thumbnail.link;
    } catch (e) {
      imageLink = attachment.link;
    }
  }
  return imageLink;
};

export const validateInput = (
  controls: any,
  controlName: string,
  value: any,
  data?:any
): any => {
  const elementType = controls[controlName].elementType;
  const displayField = controls[controlName].displayField;
  if (elementType === "checkbox") {
    let values = controls[controlName].value;
    if (typeof values === "object") {
      const index = values.indexOf(value);
      if (index !== -1) {
        values.splice(index, 1);
      } else {
        values.push(value);
      }
      value = values;
    } else {
      value = value === "true" || value === "false" ? Boolean(value) : value;
      value = values === value ? false : value;
    }
  } else if (elementType === "datepicker") {
    value = new Date(value);
  }

  let displayValue;
  if(displayField && data) {
      displayValue = data[displayField];
  }

  const updatedControls = updateObject(controls, {
    [controlName]: updateObject(controls[controlName], {
      value,
      displayValue,
      valid: checkValidity(value, controls[controlName].validation, controls),
      touched: true
    })
  });

  return {
    controls: updatedControls,
    formIsValid: validateControls(updatedControls)
  };
};

export const validateControls = (controls: any): boolean => {
  let formIsValid = true;
  for (let controlIdentifier in controls) {
    formIsValid = controls[controlIdentifier].valid && formIsValid;
  }
  return formIsValid;
};

export const initForm = (controls: any, data: any): any => {
  let validation = null;
  for (let key in data) {
    if (controls[key]) {
      validation = validateInput(controls, key, data[key], data);
      controls = validation.controls;
    }
  }

  return {
    controls: validation ? validation.controls : controls,
    formIsValid: validation ? validation.formIsValid : true
  };
};

export const getFormData = (controls: any): any => {
  const formData: any = {};

  for (let formElementIdentifier in controls) {
    let value = controls[formElementIdentifier].value;
    if (value instanceof Date) {
      value = `${value.getDate()}.${value.getMonth() +
        1}.${value.getFullYear()}`;
    }

    formData[formElementIdentifier] = value;
  }
  return formData;
};

export const controlsToFormGroups = (
  controls: any,
  inputChangedHandler: any,
  currentUser?: IUser
): any => {
  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({
      id: key,
      config: controls[key]
    });
  }

  const formGroups = formElementsArray.map(formElement => {
    let elem: any = null;

    const visibleRoles = formElement.config.visibleRoles;
    const isHidden = visibleRoles && !visibleRoles.includes(currentUser.role);
    if(isHidden) return null;

    if (formElement.config.isLabel) {
      elem = <h3 key={formElement.config.text}>{formElement.config.text}</h3>;
    } else {
      const editRoles = formElement.config.editRoles;
      const elementType = formElement.config.elementType;
      const cannotEdit = editRoles && !editRoles.includes(currentUser.role);
      
      if (elementType !== 'attachment' && cannotEdit) {
        elem = <DisplayInput key={formElement.id} formElement={formElement} />;
      } else {
        elem = (
          <Input
            key={formElement.id}
            formElement={formElement}
            changed={inputChangedHandler}
            canEdit={!cannotEdit}
          />
        );
      }
    }
    return elem;
  });

  return formGroups;
};

export const sanitize = (dirtyHtml: any, options?: any): any => {
  let defaultOptions: any = {
    allowedTags: ["p", "b", "i", "u", "h1", "h2", "h3", "a", "ol", "li", "ul"],
    allowedAttributes: {
      a: ["href", "target"]
    }
  };

  if (options) {
    defaultOptions = [...defaultOptions, ...options];
  }

  return sanitizeHtml(dirtyHtml, defaultOptions);
};

export const getObjectValue = (object: any, name: string) => {
  return object[name];
};

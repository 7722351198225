import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Alert, Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';

import ContentBox from '../../../../hoc/ContentBox/ContentBox';
import { IAppState, IProduct, IProject } from '../../../../interfaces';
import * as actions from '../../../../store/actions';

interface IStateProps {
    project: IProject,
    loading: boolean,
    error: string
}  

interface IDispatchProps {
    onGetProject: (id:string) => void
}  

interface IMatchProps {
    id: string,
    productId: string
}


interface IProps extends IStateProps, IDispatchProps, RouteComponentProps<IMatchProps> {

}

class ProductDetails extends Component<IProps> {
    state:{product:IProduct | null} = {
        product: null
    }


    async componentDidMount() {
        await this.props.onGetProject(this.props.match.params.id);
        const product = this.props.project.products.find((product) => product.id === this.props.match.params.productId);
        this.setState({
            product
        });
    }


    render () {
        const errorMessage = this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null;

        let title = null;
        let projectName = null;
        let breadCrumbs = (
            <Breadcrumb>
                <BreadcrumbItem><NavLink to='/projects'>Projects</NavLink></BreadcrumbItem>
                <BreadcrumbItem active>Loading...</BreadcrumbItem>
            </Breadcrumb>
        );
        let content = null;

        if(this.state.product !== null) {
            const product = this.state.product;
            projectName = this.props.project.name
            breadCrumbs = (
                <Breadcrumb>
                    <BreadcrumbItem><NavLink to='/projects'>Projects</NavLink></BreadcrumbItem>
                    <BreadcrumbItem><NavLink to={`/projects/${this.props.project.id}`}>{projectName}</NavLink></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.product.name}</BreadcrumbItem>
                </Breadcrumb>
            );
            title = product.name;

            let image = null;
            if(product.attachment) {
                image = <img style={styles.Image} src={product.attachment.link} alt={product.attachment.name} />;
            }

            content = (
                <Row>
                    <Col xs="12" md="2">
                        {image}
                        <p>{product.description}</p>
                    </Col>
                    <Col xs="12" md="10">
                        
                    </Col>
                </Row>
            );
        }

        return (
            <React.Fragment>
                {errorMessage}
                {breadCrumbs}
                <ContentBox 
                    size="h5" 
                    title={title}
                    loading={this.props.loading}
                    >
                    {content}
                </ContentBox>
            </React.Fragment>
        );
    }
}

const styles = {
    Image: {
        maxWidth: '100%',
        maxHeight: '100%'
    }
}

const mapStateToProps = (state:IAppState):IStateProps => {
    return {
        project: state.projects.project,
        loading: state.projects.loading,
        error: state.projects.error
    }
}

const mapDispatchToProps = (dispatch:any):IDispatchProps => {
    return {
        onGetProject: (id) => dispatch(actions.getProject(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
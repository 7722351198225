export enum SortType {
  CREATED, NAME
}

export enum SortOrder {
  ASC, DESC
}

export enum UserRole {
  ADMIN = 'ADMIN',
  USER = 'USER'
}
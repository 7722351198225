import React from "react";
import { IProjectAction, IProject, IProduct } from "../../../interfaces";

import { ResponsiveBar } from "@nivo/bar";
import { number } from "prop-types";
import { defaultChartSettings } from "../../../shared/charts";

interface IProps {
  products: IProduct[];
}

enum Keys {
  UNIT_COST = "Unit cost",
  PROFIT_MARGIN = "Profit margin"
}

interface IData {
  name: string;
  [Keys.UNIT_COST]: number;
  [Keys.PROFIT_MARGIN]: number;
}

const ProjectChartsAddedValue: React.FC<IProps> = ({ products }) => {
  if (!products) return null;

  const getData = (): Array<IData> => {
    const data: Array<IData> = [];
    products.forEach(product => {
      const calculations = product.calculations;
      if (!calculations || !calculations.length) return;
      data.push({
        name: product.name,
        [Keys.UNIT_COST]: parseFloat(product.costTotal),
        [Keys.PROFIT_MARGIN]: parseFloat(
          calculations.find(
            calculations =>
              calculations.priceStep === product.priceStep - 1 &&
              calculations.amountStep === product.amountStep - 1
          ).salesMarginPrice
        )
      });
    });
    return data;
  };


  return (
    <ResponsiveBar
      {...defaultChartSettings}
      margin={{
        ...defaultChartSettings.margin,
        left: 70,
        right: 150
      }}
      data={getData()}
      labelFormat={tick => `${parseFloat(tick.toString()).toFixed(2)} €`}
      groupMode="stacked"
      keys={[Keys.UNIT_COST, Keys.PROFIT_MARGIN]}
      indexBy="name"
      axisLeft={{
        ...defaultChartSettings.axisLeft,
        legend: "Profit margin",
        legendOffset: -60,
        format: tick => {
          return `${tick} €`;
        }
      }}
    />
  );
};

export default ProjectChartsAddedValue;

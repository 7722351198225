import { faPlus } from "@fortawesome/free-solid-svg-icons";
import _debounce from "lodash/debounce";
import React, { useState, useEffect } from "react";
import { InView } from "react-intersection-observer";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import AsyncSelect from "react-select/lib/Async";
import { ActionMeta } from "react-select/lib/types";
import { Alert, Breadcrumb, BreadcrumbItem, Progress } from "reactstrap";

import ProjectsList from "../../components/ProjectsList/ProjectsList";
import Sort from "../../components/UI/Sort/Sort";
import { SortOrder, SortType } from "../../enums";
import AdminRender from "../../hoc/Authorization/AdminRender";
import ContentBox from "../../hoc/ContentBox/ContentBox";
import { IAppState, IProject, IUser } from "../../interfaces";
import * as actions from "../../store/actions";

interface IStateProps {
  projects: Array<IProject>;
  hasMore: boolean;
  loading: boolean;
  cursor: string;
  error: string;
  search: Array<string>;
  currentUser: IUser;
}

interface IDispatchProps {
  onFindProjects: (
    startCursor: string,
    sortType: SortType,
    sortOrder: SortOrder
  ) => {};
  onClearProjectsState: () => {};
  onSearchProjects: (queryString: string) => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const Projects:React.FC<IProps> = (props) => {
  const [sort, setSort] = useState({
    type: SortType.CREATED,
    order: SortOrder.DESC
  });

  const [searchCb, setSearchCb] = useState(null);

  useEffect(() => {
    props.onFindProjects("", sort.type, sort.order);

    return () => {
      props.onClearProjectsState();
    };
  }, []);

  useEffect(() => {
    let items: Array<string> = [];
    if (props.search) {
      items = [...props.search];
    }
    if (props.error) {
      items = [];
    }
    searchCb && searchCb.callback(items);
  }, [props.search, props.error]);

  const onOpenProject = (id: string, openInNewTab: boolean): void => {
    if (openInNewTab) {
      window.open(`/projects/${id}`);
      return;
    }
    props.history.push(`/projects/${id}`);
  };

  const onLoadMore = async (loadMore: boolean) => {
    if (loadMore && props.hasMore) {
      await props.onFindProjects(props.cursor, sort.type, sort.order);
    }
  };

  const onSearchProjects = () =>
    _debounce((queryString, callback) => {
      props.onSearchProjects(queryString);
      setSearchCb({callback: callback});
    }, 300);

  const onSearchChange = (option: any, action: ActionMeta) => {
    onOpenProject(option.id, false);
  };

  const onSort = (type: SortType, order: SortOrder) => {
    props.onClearProjectsState();
    props.onFindProjects("", type, order);
    setSort({
      type,
      order
    });
  };

  const { loading, projects } = props;


  const hideSearchAndSort = loading || !projects || !projects.length;

  return (
    <React.Fragment>
      {props.error && <Alert color="danger">{props.error}</Alert>}
      <Breadcrumb>
        <BreadcrumbItem active>Projects</BreadcrumbItem>
      </Breadcrumb>
      <ContentBox
        title={(loading && "Loading..") || "Projects"}
        actions={AdminRender(
          [{ icon: faPlus, link: "/projects/add" }],
          props.currentUser
        )}
      >
        {!hideSearchAndSort && (
          <AsyncSelect
            placeholder="Search..."
            loadOptions={onSearchProjects()}
            getOptionValue={(option: any) => option.id}
            getOptionLabel={(option: any) => option.name}
            onChange={onSearchChange}
          />
        )}
        <Sort
          active={sort.type}
          order={sort.order}
          clicked={onSort}
          hide={hideSearchAndSort}
        />
        <ProjectsList
          projects={projects}
          loading={loading}
          openProject={onOpenProject}
        />
        {(loading && <Progress animated color="primary" value="100" />) || (
          <InView tag="div" onChange={onLoadMore} />
        )}
      </ContentBox>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    projects: state.projects.projects,
    loading: state.projects.loading,
    error: state.projects.error,
    cursor: state.projects.cursor,
    hasMore: state.projects.hasMore,
    search: state.projects.search
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindProjects: (startCursor, sortType, sortOrder) =>
      dispatch(actions.findProjects(startCursor, sortType, sortOrder)),
    onClearProjectsState: () => dispatch(actions.clearProjectsState()),
    onSearchProjects: queryString =>
      dispatch(actions.searchProjects(queryString))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);

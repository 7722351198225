import Slider, { createSliderWithTooltip, Marks } from 'rc-slider';
import React from 'react';

import { IProduct, ISlider } from '../../../interfaces';
import ProductSlider from '../ProductSlider/ProductSlider';
import ProductCalculationsContainer from './ProductCalculationsContainer';

const SliderWithTooltip = createSliderWithTooltip(Slider);

interface IProps {
  vat: number;
  product: IProduct;
  hide: boolean;
  salesSlider: ISlider;
  hideSliders: boolean;
  onAmountSliderChange: (id: string, step: number) => void;
  onPriceSliderChange: (id: string, step: number) => void;
}

/*
const handle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};
*/

const ProductCalculations: React.FC<IProps> = ({
  product,
  onAmountSliderChange,
  onPriceSliderChange,
  hide,
  vat,
  salesSlider,
  hideSliders,
}) => {
  if (hide) return null;
  if (!product.amountStep || !product.priceStep) return null;
  if (!product.calculations) return null;

  const productCalculation = product.calculations.find(
    (item) =>
      item.amountStep === product.amountStep - 1 &&
      item.priceStep === product.priceStep - 1
  );
  if (!productCalculation) return null;

  const salesMarks: Marks = {
    1: "+0%",
    11: `+${salesSlider.maxPercent}%`,
  };

  const retialPriceMarks: Marks = {
    1: "+0%",
    11: product.retailPriceSlider && `+${product.retailPriceSlider.maxPercent}%`
  };

  let calculationsArray: any = [
    {
      text: `Unit price (VAT ${vat}%)`,
      value: `${productCalculation.retailPrice} €`,
    },
    {
      text: `Unit price (VAT 0%)`,
      value: `${productCalculation.retailPriceWithoutVat} €`,
      slider: product.retailPriceMax && !hideSliders ? (
        <ProductSlider
          value={product.priceStep}
          marks={retialPriceMarks}
          onChange={(step: number) => onPriceSliderChange(product.id, step)}
          slider={product.retailPriceSlider}
        />
      ) : null,
    },
    {
      text: "Sales (units)",
      value: `${productCalculation.salesAmount} pcs`,
      slider: !hideSliders ? (
        <ProductSlider
          value={product.amountStep}
          marks={salesMarks}
          onChange={(step: number) => onAmountSliderChange(product.id, step)}
          slider={salesSlider}
        />
      ) : null,
    },
    {
      text: "Gross sales (VAT 0%)",
      value: `${productCalculation.salesPrice} €`,
      bold: true,
    },
    { hr: true },
    {
      text: "Unit cost (VAT 0%)",
      value: `${product.costTotal} €`,
    },
    {
      text: "Profit margin",
      value: `${productCalculation.salesMarginPrice} €`,
    },
    {
      text: "Profit margin %",
      value: `${productCalculation.salesMarginPercent} %`,
    },
    {
      text: "Gross profit (VAT 0%)",
      value: `${productCalculation.grossProfit} €`,
      bold: true,
    },
  ];


  calculationsArray.push({ hr: true });

  return <ProductCalculationsContainer calculationsArray={calculationsArray} />
};



export default ProductCalculations;

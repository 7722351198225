import React, { CSSProperties } from "react";

import { CardImg } from "reactstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import ContainerActions from "../../UI/ContainerActions/ContainerActions";
import ImageSpinner from "../../UI/ImageSpinner/ImageSpinner";
import { getAttachmentLink } from "../../../shared/utility";
import Input from "../../UI/Input/Input";

import { IAttachment, IContainerActions } from "../../../interfaces";

interface IProps {
  attachment: IAttachment;
  imageActions?: Array<IContainerActions>;
  showDownload?: boolean;
  edit?: {
    onDrop: (files: Array<File>) => void;
  };
  styles?: CSSProperties;
  containerStyles?: CSSProperties;
  label?: string;
  hide?: boolean;
  onClick?: () => void;
}

const ProductImage: React.FC<IProps> = ({
  attachment,
  imageActions,
  showDownload,
  edit,
  styles,
  containerStyles,
  label,
  hide,
  onClick
}) => {
  if (hide) return null;

  if (edit && (!attachment || !attachment.link)) {
    styles = { ...defaultStyles.EditImage, ...styles };
    containerStyles = { ...defaultStyles.EditContainer, ...containerStyles };

    return (
      <Input
        canEdit
        formElement={{
          config: {
            elementType: "attachment",
            elementConfig: {
              label,
              hint:
                "Try dropping image here, or click to select image to upload.",
              hintDragActive: "Drop image here...",
              styles,
              containerStyles,
              onDrop: edit.onDrop,
              accept: "image/*",
              disableMultiple: true
            }
          }
        }}
      />
    );
  }

  styles = { ...defaultStyles.Image, ...styles };
  containerStyles = { ...defaultStyles.ImageContainer, ...containerStyles };

  if (!attachment) return null;

  const link = getAttachmentLink(attachment);
  if (!link) return null;

  if (showDownload) {
    if (!imageActions) imageActions = [];
    imageActions.push({
      text: "Download image",
      clicked: () => {
        window.open(attachment.link);
      },
      icon: faDownload
    });
  }

  return (
    <div style={containerStyles}>
      {label ? <h5>{label}</h5> : null}
      <ContainerActions actions={imageActions}>
        <ImageSpinner
          top
          className="unselectable"
          style={styles}
          src={link}
          alt={attachment.name || "Product Image"}
          tag={CardImg}
          onClick={onClick}
        />
      </ContainerActions>
    </div>
  );
};

interface IDefaultStyles {
  [key: string]: CSSProperties;
}

const defaultStyles: IDefaultStyles = {
  ImageContainer: {
    textAlign: "center",
    padding: "1rem 1rem 0 1rem",
    minHeight: "16rem"
  },
  Image: {
    maxHeight: "15rem",
    width: "initial",
    maxWidth: "100%"
  },
  EditContainer: {
    padding: "1rem 1rem 0 1rem"
  },
  EditImage: {
    height: "15rem"
  }
};

export default ProductImage;

import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Breadcrumb, BreadcrumbItem, Table } from "reactstrap";

import UserRender from "../../hoc/Authorization/UserRender";
import ContentBox from "../../hoc/ContentBox/ContentBox";
import { IAppState, IUser } from "../../interfaces";
import * as actions from "../../store/actions";

interface IStateProps {
  users: Array<IUser> | null;
  loading: boolean;
}

interface IDispatchProps {
  onFindUsers: () => {};
}

interface IProps extends IStateProps, IDispatchProps, RouteComponentProps {}

const users = (props: IProps) => {
  useEffect(() => {
    props.onFindUsers();
  }, []);

  const editHandler = (user: IUser) => {
    props.history.push(`/users/${user.id}`);
  };

  return (
    <React.Fragment>
      <Breadcrumb>
        <BreadcrumbItem active>Users</BreadcrumbItem>
      </Breadcrumb>
      <ContentBox
        title="Users"
        actions={[{ icon: faPlus, link: "/users/add" }]}
        loading={props.loading}
        hideHr={true}
      >
        <Table hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Projects</th>
            </tr>
          </thead>
          <tbody>
            {props.users != null &&
              props.users.map(user => (
                <tr
                  key={user.id}
                  onClick={() => editHandler(user)}
                  className="pointer"
                >
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    {UserRender(
                      user.projects ? user.projects.length : 0,
                      user,
                      "-"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </ContentBox>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    users: state.users.users,
    loading: state.users.loading
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onFindUsers: () => dispatch(actions.findUsers())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(users);

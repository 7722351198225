import React, { Component, ComponentClass } from "react";
import { connect } from "react-redux";
import ContentBox from "../ContentBox/ContentBox";

import { IAppState, IUser } from "../../interfaces";
import { UserRole } from "../../enums";

interface IProps {
  currentUser: IUser;
}

const authorization = (allowedRoles: Array<UserRole>) => (WrappedComponent: any) => {
  const withAuthorization = (props: IProps) => {
    const { currentUser } = props;
    if (allowedRoles.includes(currentUser.role)) {
      return <WrappedComponent {...props} />;
    } else {
      return (
        <ContentBox title="No access" loading={false}>
          You don't have permission to view this page.
        </ContentBox>
      );
    }
  };

  const mapStateToProps = (state: IAppState) => {
    return {
      currentUser: state.auth.currentUser
    };
  };

  return connect(mapStateToProps)(withAuthorization);
};

export default authorization;

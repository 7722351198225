import React from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";

import Navigation from "../../components/Navigation/Navigation";
import { IAppState } from "../../interfaces";

const classes = require("./Layout.module.css");

interface IStateProps {
  isAuthenticated: boolean;
}

interface IProps extends IStateProps {
  children: any;
}

const layout = (props: IProps) => {
  return (
    <div className={classes.root}>
      <Navigation isAuthenticated={props.isAuthenticated} />
      <Container fluid={true} className={classes.Container}>
        {props.children}
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.currentUser != null
  };
};

export default connect(mapStateToProps)(layout);

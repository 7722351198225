import React from 'react';

interface IProps {
    valueDiff: number,
    valueDiffPercent: number
}

const diffText = ({ valueDiff, valueDiffPercent}:IProps) => {

    if(!valueDiff) return null;

    let result = valueDiff.toString();

    if(valueDiffPercent) {
        result += `/${valueDiffPercent}%`;
    }

    return (
        <React.Fragment>
            ({result})
        </React.Fragment>
    )
};


export default diffText;
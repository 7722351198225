import React from 'react';

import { IProduct } from '../../../../interfaces';
import ProductCalculationsContainer from '../ProductCalculationsContainer';

interface IProps {
  product: IProduct;
  hide: boolean;
  investmentPeriod: number;
}

const ProductInvestment:React.FC<IProps> = ({ product, hide, investmentPeriod }) => {
  if (hide) return null;

  let investmentArray:any[] = [];

  if (product.costTotal || product.investmentTotal) {
    investmentArray = investmentArray.concat([
      { hr: true },
      {
        text: "Total investment",
        value: `${product.investmentTotal} €`,
      },
      { text: "Investment period, years", value: `${investmentPeriod}` },
    ]);

    if(product.amountStep || product.priceStep || product.calculations) {
        const productCalculation = product.calculations.find(
        (item) =>
          item.amountStep === product.amountStep - 1 &&
          item.priceStep === product.priceStep - 1
      );
      
      if(productCalculation) {
        if (productCalculation.incProfitPerYear) {
          investmentArray = investmentArray.concat([
            {
              text: "Incremental profit per year",
              value: `${productCalculation.incProfitPerYear}`,
            },
            {
              text: "Incremental profit per period",
              value: `${productCalculation.incProfitPerPeriod}`,
            },
            { text: "ROI", value: `${productCalculation.roi}`, bold: true },
            {
              text: "Payback period, months",
              value: `${productCalculation.roiMonths}`,
              bold: true,
            },
          ]);
        }
      }
    }
  
    
    
  }


  return <ProductCalculationsContainer calculationsArray={investmentArray} />;
}

export default ProductInvestment;

import React, { useEffect, useState } from "react";
import Truncate from "react-truncate";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Modal,
  ModalBody,
} from "reactstrap";

import { IProduct, ISlider } from "../../interfaces";
import ScoreCircle from "../UI/ScoreCircle/ScoreCircle";
import ProductCalculations from "./ProductCalculations/ProductCalculations";
import ProductImage from "./ProductImage/ProductImage";
import ProductScores from "./ProductScores/ProductScores";
import ProductInvestment from "./ProductCalculations/ProductInvestment/ProductInvestment";
import ImageSpinner from "../UI/ImageSpinner/ImageSpinner";

const classes = require("./Product.module.scss");

interface IProps {
  vat: number;
  investmentPeriod: number;
  // amountStep?: number,
  // priceStep?: number,
  product?: IProduct;
  hideDetails?: boolean;
  buttons?: Array<any>;
  salesSlider: ISlider;
  hideSliders: boolean;
  onUpdateAmountStep: (id: string, step: number) => void;
  onUpdatePriceStep: (id: string, step: number) => void;
}

interface IState {
  amountStep: number;
  priceStep: number;
  descriptionLines: number;
}

const ProductView: React.FC<IProps> = (props) => {
  // const [amountStep, setAmountStep] = useState(props.amountStep);
  // const [priceStep, setPriceStep] = useState(props.priceStep);
  const [descriptionLines, setDescriptionLines] = useState(3);
  const [valueProfileOpen, setValueProfileOpen] = useState(false);

  /*
  useEffect(() => {
    setAmountStep(props.amountStep);
  }, [props.amountStep]);

  useEffect(() => {
    setPriceStep(props.priceStep);
  }, [props.priceStep]);
  */

  const onToggleDescription = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    setDescriptionLines(!descriptionLines ? 3 : null);
  };

  const valueProfileHandler = () => {
    setValueProfileOpen(true);
  };

  const {
    product,
    hideDetails,
    buttons,
    vat,
    investmentPeriod,
    salesSlider,
    hideSliders
  } = props;

  return (
    <>
      {product.valueProfileAttachment && (
        <Modal
          isOpen={valueProfileOpen}
          toggle={() => setValueProfileOpen(false)}
          size="lg"
        >
          <ModalBody>
            <ImageSpinner
              src={product.valueProfileAttachment.link}
              alt="Value Profile"
              tag="img"
              style={{ maxWidth: "100%" }}
            />
          </ModalBody>
        </Modal>
      )}

      <Col xs="12" lg="3" md="6" sm="6">
        <Card className={classes.Card}>
          <ProductImage
            attachment={product.attachment}
            showDownload={!hideDetails}
          />
          <CardBody>
            <CardTitle className={classes.ProductTitle}>
              {product.name}
            </CardTitle>
            {!hideDetails && (
              <CardText className={`pre ${classes.ProductDescription}`}>
                <Truncate
                  lines={descriptionLines}
                  ellipsis={
                    <span>
                      ...{" "}
                      <a href="/#" onClick={onToggleDescription}>
                        show more
                      </a>
                    </span>
                  }
                >
                  {product.description}
                </Truncate>
                {descriptionLines ? null : (
                  <span>
                    {" "}
                    <a href="/#" onClick={onToggleDescription}>
                      show less
                    </a>
                  </span>
                )}
              </CardText>
            )}
            <ProductCalculations
              vat={vat}
              salesSlider={salesSlider}
              product={product}
              onAmountSliderChange={props.onUpdateAmountStep}
              onPriceSliderChange={props.onUpdatePriceStep}
              hide={hideDetails}
              hideSliders={hideSliders}
            />
            <ProductScores product={product} hide={hideDetails} />
            <ProductImage
              attachment={product.valueProfileAttachment}
              showDownload={!hideDetails}
              containerStyles={{
                padding: 0,
                marginTop: "1rem",
                minHeight: "auto",
                marginLeft: "-1rem",
                marginRight: "-1rem",
              }}
              styles={{ maxHeight: "auto" }}
              label="Value profile"
              hide={hideDetails}
              onClick={valueProfileHandler}
            />
            <ScoreCircle
              score={product.totalValueScore}
              scoreDiff={product.totalValueScoreDiff}
              hide={hideDetails}
            />
            <ProductInvestment
              hide={hideDetails}
              product={product}
              investmentPeriod={investmentPeriod}
            />
            {buttons &&
              buttons.map((button) => (
                <Button
                  key={button.text}
                  color="primary"
                  onClick={button.onClick}
                >
                  {button.text}
                </Button>
              ))}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ProductView;

import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';
import Textarea from 'react-textarea-autosize';
import { Button, CustomInput, FormGroup, Input, Label, Table } from 'reactstrap';

import { IAttachment } from '../../../interfaces';

const classes = require('./Input.module.scss');


const input = ({ formElement, changed, canEdit }:any) => {

    let inputElement = null;
    let attachmentsTable = null;
    switch (formElement.config.elementType) {
        case ('input'):
            inputElement = <Input 
                type={formElement.config.elementConfig.type} 
                id={formElement.id} 
                placeholder={formElement.config.elementConfig.label} 
                value={formElement.config.value}
                onChange={(event) => changed(event, formElement.id)}
                invalid={!formElement.config.valid} 
                disabled={formElement.config.disabled}
                />;
            break;
        case ('textarea'):
            let classNames = ['form-control'];
            if(!formElement.config.valid) {
                classNames.push('is-invalid');
            }

            inputElement = <Textarea
                id={formElement.id}
                className={classNames.join(' ')}
                placeholder={formElement.config.elementConfig.label}
                value={formElement.config.value}
                onChange={(event) => changed(event, formElement.id)}
                minRows={3}
                disabled={formElement.config.disabled}
                />;
            break;
        case ('checkbox'):
        case ('radio'):
            inputElement = formElement.config.elementConfig.options.map((option:any, i:number) => 
                <CustomInput 
                    key={formElement.id + i}
                    type={formElement.config.elementType} 
                    id={formElement.id + i}
                    name={formElement.id}
                    label={option.displayValue}
                    value={option.value}
                    checked={formElement.config.value === option.value || (typeof formElement.config.value === 'object' && formElement.config.value.indexOf(option.value) !== -1)}
                    onChange={(event) => changed(event, formElement.id)}
                    invalid={!formElement.config.valid} />
            );
            inputElement = <div>{inputElement}</div>;
            break;
        case ('attachment'):
            inputElement = (
                <div style={formElement.config.elementConfig.containerStyles}>
                    <Dropzone 
                        onDrop={formElement.config.elementConfig.onDrop}
                        accept={formElement.config.elementConfig.accept}
                        multiple={!formElement.config.elementConfig.disableMultiple}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                            return (
                                <div className={classes.Dropzone} {...getRootProps()} style={formElement.config.elementConfig.styles}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                        <p>{formElement.config.elementConfig.hintDragActive}</p> :
                                        <p>{formElement.config.elementConfig.hint}</p>
                                    }
                                    
                                </div>
                            )
                        }}
                    </Dropzone>
                </div>
            );
            if(!canEdit) {
                inputElement = null;
            }

            const value = formElement.config.value;

            if(value) {
                attachmentsTable = (
                    <Table>
                        <tbody>
                        {value.map((attachment:IAttachment, index:number) => {
                            let actionCol = null;
                            const onDelete = formElement.config.elementConfig.onDelete;
                            if(onDelete && canEdit) {
                                actionCol = (
                                    <td>
                                        <Button color="danger" onClick={() => onDelete(attachment.id, index) }>Delete</Button>
                                    </td>
                                )
                            }
                            return (
                                <tr key={attachment.link}>
                                    <td>
                                        <a href={attachment.link} target="_blank" rel="noopener noreferrer">{attachment.name}</a>
                                    </td>
                                    {actionCol}
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                );
            }
            break;
        case ('datepicker'): 
            inputElement = (<div>
                                <DatePicker
                                className="form-control" 
                                selected={formElement.config.value ? new Date(formElement.config.value) : null} 
                                onChange={(value) => changed({target: { value }}, formElement.id)} 
                                />
                            </div>);
            break;
        default:
            inputElement = <Input 
                type={formElement.config.elementConfig.type} 
                id={formElement.id} 
                value={formElement.config.value}
                placeholder={formElement.config.elementConfig.label} 
                onChange={(event) => changed(event, formElement.id)}
                invalid={!formElement.config.valid} />;
    }

    let label = null;
    if(formElement.config.elementConfig.label !== null) {
        label = <Label for={formElement.id}>{formElement.config.elementConfig.label}</Label>;
    }

    let inputEl = (
        <FormGroup>
            {label}
            {inputElement}
            {attachmentsTable}
        </FormGroup>    
    );
    if(label === null && formElement.config.elementType === 'attachment') {
        inputEl = inputElement;
    }

    return inputEl;
}

export default input;
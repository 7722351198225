import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Alert, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Progress } from 'reactstrap';

import { IComment, IUser } from '../../../../interfaces';
import { sanitize } from '../../../../shared/utility';
import CommentsEditor from '../CommentsEditor';

const classes = require('./Comment.module.scss');

interface IProps {
    currentUser: IUser,
    comment: IComment,
    onDelete: (id:string) => void,
    onUpdate: (id:string, value:string) => void
}

interface IState {
    optionsOpen: boolean,
    edit: boolean,
    loading: boolean
}

class Comment extends Component<IProps, IState> {
    state = {
        optionsOpen: false,
        edit: false,
        loading: false
    }

    shouldComponentUpdate(nextProsp:IProps, nextState:IState) {
        return (
            this.state.optionsOpen !== nextState.optionsOpen || 
            this.state.edit !== nextState.edit ||
            this.state.loading !== nextState.loading
        );
    }

    toggleDropdown = () => {
        this.setState((prevState) => ({
            optionsOpen: !prevState.optionsOpen
        }))
    }

    toggleEdit = () => {
        this.setState((prevState) => ({
            edit: !prevState.edit
        }));
    }

    canEdit = (comment:IComment) => {
        const { currentUser } = this.props;
        const { createdBy } = comment;
        if(!currentUser) return false;
        if(currentUser.role === 'ADMIN') return true;
        if(!createdBy) return false;
        return currentUser.id === createdBy.id;
    }

    renderDropdown = (comment:IComment) => {
        if(!this.canEdit(comment)) {
            return null;
        }

        return (
            <Dropdown 
                isOpen={this.state.optionsOpen} 
                toggle={this.toggleDropdown} 
                className={classes.Dropdown}
                direction="left"
            >
                <DropdownToggle tag="span">
                    <div className={classes.Options}>
                        <FontAwesomeIcon size="xs" icon={faEllipsisV} />
                    </div>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem className="pointer" onClick={this.toggleEdit}>Edit</DropdownItem>
                    <DropdownItem className="pointer" onClick={() => this.props.onDelete(comment.id)}>Delete</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    onUpdate = async (id:string, value:string) => {
        this.setState({
            loading: true
        });
        await this.props.onUpdate(id, value);
        this.setState({
            loading: false,
            edit: false
        });
    }

    render() {
        const { comment, currentUser } = this.props;

        let updatedEl = null;

        const { value, created, createdBy, updated, updatedBy } = comment;
        if(updated) {
            updatedEl = (
                <React.Fragment>
                    <br />Updated: {updatedBy.name} {updated}
                </React.Fragment>
            );
        }

        let content = (
            <React.Fragment>
                {this.renderDropdown(comment)}
                <div dangerouslySetInnerHTML={{ __html: sanitize(value) }}></div>
                <hr style={{marginTop: '0', marginBottom: '0.5rem'}}/>
                <small>
                    <i>
                        Created: {createdBy.name} {created}
                        {updatedEl}
                    </i>
                    
                </small>
            </React.Fragment>
        )

        if(this.state.edit) {
            content = (
                <CommentsEditor 
                    comment={comment}
                    onUpdate={this.onUpdate}
                    onCancel={this.toggleEdit}
                />
            )
        }

        if(this.state.loading) {
            content = <Progress color="primary" animated value="100" />;
        }
        

        return (
            <Alert key={comment.id} color={currentUser.id === comment.createdBy.id ? 'info' : 'secondary'} className={classes.Comment}>
                {content}
            </Alert>
        )
    }
    
}


export default Comment;
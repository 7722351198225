import React, { Component } from 'react';
import { Progress, Alert } from 'reactstrap';

import Comment from './Comment/Comment';
import CommentsEditor from './CommentsEditor';

import { IComment, IUser} from '../../../interfaces';

interface IProps {
    loading: boolean,
    error: string,
    comments: Array<IComment>,
    currentUser: IUser,
    onDelete: (id:string) => void,
    onUpdate: (id:string, value:string) => void,
    onSave: (value: string) => void
}

const comments = (props:IProps) => {

    const renderComments = () => {
        const { comments, onDelete, onUpdate, currentUser } = props;
        
        if(!comments || comments.length === 0) {
            return <p>No notes</p>
        }
        return (
            <div>
                {comments.map((comment) => (
                    <Comment 
                        key={comment.id} 
                        comment={comment} 
                        onDelete={onDelete} 
                        onUpdate={onUpdate}
                        currentUser={currentUser}
                    /> 
                ))}
            </div>
        )
    }

    const { loading, error } = props;

    let loadingEl = loading && <Progress animated color="primary" value="100" style={{ marginBottom: '1rem' }} />;
    let errorEl = error && <Alert color="danger">{error}</Alert>;

    return (
        <div>
            <h5>Notes</h5>
            <CommentsEditor onSave={props.onSave} />
            <hr/>
            {loadingEl}
            {errorEl}
            {renderComments()}
        </div>
    )
    
}


export default comments;
// import { Link } from 'react-router-dom';

import React from "react";

import Logo from "../Logo/Logo";
import Menu from "./Menu/Menu";

const classes = require("./Navigation.module.scss");

interface IProps {
  isAuthenticated: boolean;
}

const navigation = (props: IProps) => {
  return (
    <div className={classes.root}>
      <Logo />
      {props.isAuthenticated && <Menu />}
    </div>
  );
};

export default navigation;

import React from 'react';
import { Row, Col } from 'reactstrap';

const classes = require('./ProductCalculationsContainer.module.scss');


interface IProps {
  calculationsArray: any[];
}

const ProductCalculationsContainer:React.FC<IProps> = ({ calculationsArray }) => {
  return (
    <div className={classes.CalculationsContainer}>
      {calculationsArray.map((item: any, index: number) => {
        if (item.hr) {
          return (
            <hr
              key={index}
              style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
            />
          );
        }

        let valueStyles: any = {};
        if (item.bold) {
          valueStyles.fontWeight = "bold";
        }
        return (
          <div key={item.text}>
            <Row>
              <Col sm={item.slider ? 5 : 12} className="pr-0">
                <label>{item.text}</label>
                <p style={valueStyles}>{item.value}</p>
              </Col>
              {item.slider && (
                <Col sm="7" style={{ marginTop: "0.5rem" }}>
                  {item.slider}
                </Col>
              )}
            </Row>
          </div>
        );
      })}
    </div>
  );
}

export default ProductCalculationsContainer;
import { faShareAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'reactstrap';

import * as actions from '../../store/actions';
import ConfirmationDialog from '../UI/ConfirmationDialog/ConfirmationDialog';
import ShareDialog from './ShareDialog/ShareDialog';

import { IUser, IAppState, IProject } from '../../interfaces';

const classes = require('./Share.module.scss');

interface IStateProps {
    currentUser: IUser,
    loading: boolean,
    error: string,
    shareErrors: Array<any>
}  

interface IDispatchProps {
    onShareProject: (id:string, users:Array<IUser>) => void
}  

interface IProps extends IStateProps, IDispatchProps {
    project: IProject
}

class Share extends Component<IProps> {
    state:any = {
        isOpen: false,
        isShared: true,
        tooltipOpen: false
    }

    toggleDialog = () => {
        this.setState((prevState:any) => {
            return {
                isOpen: !prevState.isOpen
            }
        });
    }

    toggleTooltip = () => {
        this.setState((prevState:any) => {
            return {
                tooltipOpen: !prevState.tooltipOpen
            }
        });   
    }


    onShare = async (users:Array<IUser>) => {
        const data:any = { users };

        await this.props.onShareProject(this.props.project.id, data);


        this.setState({
            isOpen: false,
            shareErrorsOpen: this.props.shareErrors ? true : false
        });
    }


    render() {
        let icon = faShareAlt;
        let tooltip = null;
        if (this.props.project.sharedUsers) {
            icon = faUserFriends;

            tooltip = (
                <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} trigger="hover" target="shared-tooltip" toggle={this.toggleTooltip} innerClassName={classes.TooltipInner}>
                    Shared with:
                    {this.props.project.sharedUsers.map((user:IUser) => 
                        <div key={user.id}> - {user.label}</div>    
                    )}
                </Tooltip>
            );
        }

        return (
            <React.Fragment>
                <ShareDialog
                    open={this.state.isOpen} 
                    onCancel={this.toggleDialog}
                    onOk={this.onShare}
                    loading={this.props.loading}
                />

                <ConfirmationDialog 
                    open={this.state.shareErrorsOpen} 
                    title="Cannot share with following emails"
                    onOk={() => this.setState({ shareErrorsOpen: false})}
                    okButtonText="Ok"
                >
                    {
                        this.props.shareErrors && this.props.shareErrors.map((item) => (
                          <div key={item.label}>- {item.label}: {item.message}</div>  
                        ))
                    }
                </ConfirmationDialog>

                <Button onClick={this.toggleDialog } color="link" id="shared-tooltip">
                    <FontAwesomeIcon size="2x" icon={icon} />
                </Button>
                {tooltip}
                
            </React.Fragment>
        )
    }
};

const mapStateToProps = (state:IAppState):IStateProps => {
    return {
        currentUser: state.auth.currentUser,
        loading: state.projects.loading,
        error: state.projects.error,
        shareErrors: state.projects.shareErrors
    };
};

const mapDispatchToProps = (dispatch:any):IDispatchProps => {
    return {
        onShareProject: (id, users) => dispatch(actions.shareProject(id, users))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Share);
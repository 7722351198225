import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, RouteComponentProps } from "react-router-dom";
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Form,
  Row
} from "reactstrap";

import ProductEdit from "../../../components/Product/ProductEdit";
import ConfirmationDialog from "../../../components/UI/ConfirmationDialog/ConfirmationDialog";
import ContentBox from "../../../hoc/ContentBox/ContentBox";
import { IAppState, IProduct, IProject, IUser } from "../../../interfaces";
import {
  controlsToFormGroups,
  getFormData,
  initForm,
  updateObject,
  validateInput
} from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { UserRole } from "../../../enums";
import AdminRender from '../../../hoc/Authorization/AdminRender';

interface IStateProps {
  currentUser: IUser;
  project: IProject;
  loading: boolean;
  success: boolean;
  error: string;
}

interface IDispatchProps {
  onGetProject: (id: string) => {};
  onSaveProject: (formData: any) => {};
  onUpdateProject: (id: string, formData: any) => {};
  onDeleteProject: (id: string) => {};
  onDeleteProjectAttachment: (projectId: string, id: string) => {};
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatchProps> {}

class ProjectEdit extends Component<IProps> {
  state: any = {
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          label: "Case title",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false,
        editRoles: [UserRole.ADMIN]
      },
      description: {
        elementType: "textarea",
        elementConfig: {
          label: "Description",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        editRoles: [UserRole.ADMIN]
      },
      projectDate: {
        elementType: "datepicker",
        elementConfig: {
          label: "Date"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      labelProject: {
        text: "Project",
        valid: true,
        isLabel: true,
        visibleRoles: [UserRole.ADMIN]
      },
      projectName: {
        elementType: "input",
        elementConfig: {
          label: "Project name",
          type: "text"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      productName: {
        elementType: "input",
        elementConfig: {
          label: "Case product",
          type: "text"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      retailPrice: {
        elementType: "input",
        elementConfig: {
          label: "Current retail price",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      labelData: {
        text: "Data",
        valid: true,
        isLabel: true,
        visibleRoles: [UserRole.ADMIN]
      },
      dataInfo: {
        elementType: "input",
        elementConfig: {
          label: "Sample size",
          type: "text"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      dataGender: {
        elementType: "input",
        elementConfig: {
          label: "Gender",
          type: "text"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      dataAge: {
        elementType: "input",
        elementConfig: {
          label: "Average age (range)",
          type: "text"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      productUse: {
        elementType: "textarea",
        elementConfig: {
          label: "Product use",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      otherCriteria: {
        elementType: "textarea",
        elementConfig: {
          label: "Other criteria",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      labelDetails: {
        text: "Details",
        valid: true,
        isLabel: true,
        visibleRoles: [UserRole.ADMIN]
      },
      aim: {
        elementType: "textarea",
        elementConfig: {
          label: "Aim",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      researchTask: {
        elementType: "textarea",
        elementConfig: {
          label: "Research task",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      attachments: {
        elementType: "attachment",
        elementConfig: {
          label: "Attachments",
          hint:
            "Try dropping some files here, or click to select files to upload.",
          hintDragActive: "Drop files here...",
          onDrop: (files: Array<File>) => this.onDropzoneDrop(files),
          onDelete: (id: string, index: number) =>
            this.onDropzoneDelete(id, index)
        },
        validation: {
          required: false
        },
        value: null,
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      labelNumbers: {
        text: "Figures",
        valid: true,
        isLabel: true
      },
      minSalesAmount: {
        elementType: "input",
        elementConfig: {
          label: "Minimum sales (pcs)",
          type: "number"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      maxSalesAmount: {
        elementType: "input",
        elementConfig: {
          label: "Maximum sales (pcs)",
          type: "number"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      vat: {
        elementType: "input",
        elementConfig: {
          label: "VAT %",
          type: "number"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      investmentPeriod: {
        elementType: "input",
        elementConfig: {
          label: "Investment period years",
          type: "number"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      }
    },
    products: [],
    controlsIsValid: false,
    productsIsValid: false,
    deleteDialog: {
      open: false
    }
  };

  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.props.onGetProject(this.props.match.params.id);

      if (this.props.project !== null) {
        const data = initForm({ ...this.state.controls }, this.props.project);

        const products = this.props.project.products;

        this.setState({
          controls: data.controls,
          controlsIsValid: data.formIsValid,
          products
        });
      }
    } else {
      this.setState({
        products: [this.newProduct(), this.newProduct()]
      });
    }
  }

  submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const id = this.props.project ? this.props.project.id : undefined;
    const data: any = getFormData(this.state.controls);

    data.products = this.state.products;

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));

    for (let product of data.products) {
      if (product.attachment instanceof File) {
        formData.append(
          `product_${product.id ? product.id : product.tempId}`,
          product.attachment
        );
      }
      if (product.valueProfileAttachment instanceof File) {
        formData.append(
          `valueProfile_${product.id ? product.id : product.tempId}`,
          product.valueProfileAttachment
        );
      }
    }

    let attachments = this.state.controls.attachments.value;
    if (attachments) {
      for (let attachment of attachments) {
        if (attachment instanceof File) {
          formData.append("files[]", attachment);
        }
      }
    }

    if (id) {
      await this.props.onUpdateProject(id, formData);
    } else {
      await this.props.onSaveProject(formData);
    }

    //this.props.history.push(`/projects`);
  };

  onDropzoneDrop = (files: Array<File> | null) => {
    if (files) {
      for (let file of files) {
        file = Object.assign(file, { link: URL.createObjectURL(file) });
      }

      let values = this.state.controls.attachments.value || [];
      values = values.concat(files);
      const validation = validateInput(
        this.state.controls,
        "attachments",
        values
      );

      this.setState({
        controls: validation.controls,
        controlsIsValid: validation.formIsValid
      });
    }
  };

  onDropzoneDelete = (id: string, index: number) => {
    if (id) {
      this.setState({
        deleteDialog: {
          open: true,
          title: "Delete attachment",
          content:
            "Are you sure you want to delete attachment from project? This will be permanent!",
          onDelete: async () => {
            await this.props.onDeleteProjectAttachment(
              this.props.project.id,
              id
            );
            if (this.props.error) {
              this.setState({
                deleteDialog: {
                  open: false
                }
              });
            } else {
              this.removeAttachment(index);
            }
          }
        }
      });
    } else {
      this.removeAttachment(index);
    }
  };

  removeAttachment = (index: number) => {
    const array = this.state.controls.attachments.value.slice();
    array.splice(index, 1);
    const validation = validateInput(this.state.controls, "attachments", array);

    this.setState({
      controls: validation.controls,
      controlsIsValid: validation.formIsValid,
      deleteDialog: {
        open: false
      }
    });
  };

  inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string
  ) => {
    const validation = validateInput(
      this.state.controls,
      controlName,
      event.target.value
    );

    this.setState({
      controls: validation.controls,
      controlsIsValid: validation.formIsValid
    });
  };

  renderProducts = () => {
    return this.state.products.map((product: IProduct, index: number) => (
      <ProductEdit
        key={product.id || product.tempId}
        index={index}
        product={product}
        removed={this.onRemoveProductHandler}
        copy={this.onCopyProductHandler}
        changed={this.productChangedHandler}
        canRemove={this.canRemoveProduct()}
        canCopy={this.canAddProduct()}
        currentUser={this.props.currentUser}
      />
    ));
  };

  onAddProductHandler = () => {
    const products = [...this.state.products, this.newProduct()];
    this.setState({
      products,
      productsIsValid: this.productsIsValid(products)
    });
  };

  onRemoveProductHandler = (index: number, id: string) => {
    const products = this.state.products.filter(
      (product: IProduct, i: number) => i !== index
    );
    this.setState({
      products,
      productsIsValid: this.productsIsValid(products)
    });
  };

  onCopyProductHandler = (product: IProduct) => {
    const copy = {
      ...product,
      tempId: this.newProduct().tempId
    };
    if (product.id) {
      delete copy.id;
      copy.copyAttachmentFrom = product.id;
      copy.copyValueProfileAttachmentFrom = product.id;
    }
    const products = [...this.state.products, copy];
    this.setState({
      products,
      productsIsValid: this.productsIsValid(products)
    });
  };

  productChangedHandler = (index: number, product: IProduct) => {
    this.setState((prevState: any) => {
      let products = [...prevState.products];
      products[index] = updateObject(products[index], product);
      return { products, productsIsValid: this.productsIsValid(products) };
    });
  };

  productsIsValid = (products: any) => {
    let productsIsValid = true;
    for (let product of products) {
      productsIsValid = productsIsValid && product.isValid;
    }
    return productsIsValid;
  };

  newProduct = () => {
    return { tempId: _.uniqueId() };
  };

  formIsValid = () => this.state.controlsIsValid && this.state.productsIsValid;

  onDeleteProject = () => {
    this.setState({
      deleteDialog: {
        open: true,
        title: "Delete project",
        content: "Are you sure you want to delete project?",
        onDelete: async () => {
          await this.props.onDeleteProject(this.props.project.id);
        }
      }
    });
  };

  canAddProduct = () => {
    return this.state.products.length < 5 && this.props.currentUser.role === UserRole.ADMIN;
  };

  canRemoveProduct = () => {
    return this.state.products.length > 2 && this.props.currentUser.role === UserRole.ADMIN;
  };

  render() {
    const formGroups = controlsToFormGroups(
      this.state.controls,
      this.inputChangedHandler,
      this.props.currentUser
    );

    let title = null;
    if (!this.props.match.params.id) {
      title = "New project";
    } else if (this.props.project !== null) {
      title = this.props.project.name;
    }

    let deleteButton = null;
    if (this.props.project !== null) {
      deleteButton = (
        <Button
          type="button"
          color="danger"
          onClick={this.onDeleteProject}
          style={{ marginLeft: "0.5rem" }}
        >
          Delete
        </Button>
      );
    }

    let addProductButton = null;
    if (this.canAddProduct()) {
      addProductButton = (
        <span onClick={this.onAddProductHandler} style={{ cursor: "pointer" }}>
          <FontAwesomeIcon size="1x" icon={faPlus} />
        </span>
      );
    }

    let form = (
      <Form onSubmit={this.submitHandler} noValidate>
        {formGroups}
        <h3>Products {addProductButton}</h3>
        <Row>{this.renderProducts()}</Row>
        <Button type="submit" color="primary" disabled={!this.formIsValid()}>
          Submit
        </Button>
        {AdminRender(deleteButton, this.props.currentUser)}
      </Form>
    );

    let breadCrumbs = (
      <Breadcrumb>
        <BreadcrumbItem>
          <NavLink to="/projects">Projects</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>{title}</BreadcrumbItem>
      </Breadcrumb>
    );

    if (this.props.project !== null) {
      breadCrumbs = (
        <Breadcrumb>
          <BreadcrumbItem>
            <NavLink to="/projects">Projects</NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={`/projects/${this.props.project.id}`}>{title}</NavLink>
          </BreadcrumbItem>
          <BreadcrumbItem active>Edit</BreadcrumbItem>
        </Breadcrumb>
      );
    }

    let redirect = null;
    if (this.props.success) {
      if (this.props.project !== null) {
        redirect = <Redirect to={`/projects/${this.props.project.id}`} />;
      } else {
        redirect = <Redirect to={`/projects`} />;
      }
    }
    const errorMessage = this.props.error ? (
      <Alert color="danger">{this.props.error}</Alert>
    ) : null;

    return (
      <React.Fragment>
        <ConfirmationDialog
          open={this.state.deleteDialog.open}
          title={this.state.deleteDialog.title}
          onOk={this.state.deleteDialog.onDelete}
          onCancel={() => this.setState({ deleteDialog: { open: false } })}
          loading={this.props.loading}
        >
          {this.state.deleteDialog.content}
        </ConfirmationDialog>
        {redirect}
        {errorMessage}
        {breadCrumbs}
        <ContentBox title={title} loading={this.props.loading}>
          {form}
        </ContentBox>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    project: state.projects.project,
    loading: state.projects.loading,
    success: state.projects.success,
    error: state.projects.error
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetProject: id => dispatch(actions.getProject(id)),
    onSaveProject: formData => dispatch(actions.saveProject(formData)),
    onUpdateProject: (id, formData) =>
      dispatch(actions.updateProject(id, formData)),
    onDeleteProject: id => dispatch(actions.deleteProject(id)),
    onDeleteProjectAttachment: (projectId, id) =>
      dispatch(actions.deleteProjectAttachment(projectId, id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectEdit);

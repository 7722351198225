import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Progress } from 'reactstrap';

interface IProps {
    open: boolean,
    onCancel?: () => any,
    onOk: () => any,
    title:string,
    children: any,
    loading?: boolean,
    okButtonText?:string
}

const confirmationDialog = ({open, onCancel, onOk, title, children, loading, okButtonText}:IProps) => {

    
    let content = children;
    if(loading) {
        content = <Progress animated color="primary" value="100" />;
    }

    let cancelButton = null;
    if(onCancel) {
        cancelButton = <Button color="secondary" onClick={onCancel} disabled={loading}>Cancel</Button>;
    }

    return (
        <Modal isOpen={open} toggle={loading ? null : onCancel}>
            <ModalHeader toggle={loading ? null : onCancel}>{title}</ModalHeader>
            <ModalBody>{content}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onOk} disabled={loading}>{okButtonText ? okButtonText : 'Confirm'}</Button>
                {cancelButton}
            </ModalFooter>
        </Modal>
    );
}

export default confirmationDialog;
import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { IAuthState, IAuthAction } from '../../interfaces';


const initialState:IAuthState = {
    email: null,
    currentUser: null,
    loading: false,
    error: null,
    redirect: null
};

const storeAuthRedirect = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, { redirect: action.redirect });
}

const authStart = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, { error: null, loading: true});
}

const authSuccess = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        email: action.email,
        currentUser: action.currentUser,
        error: null,
        loading: false,
        redirect: null
    });
}

const authFail = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const logoutStart = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, { error: null, loading: true});
}

const logoutSuccess = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        currentUser: null,
        error: null,
        loading: false,
        email: null
    });
}

const logoutFail = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};


const getCurrentUserStart = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, { error: null, loading: true});
}

const getCurrentUserSuccess = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        currentUser: action.currentUser,
        error: null,
        loading: false
    });
}

const getCurrentUserFail = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        loading: false,
        currentUser: null
    });
};


const clearError = (state:IAuthState, action:IAuthAction):IAuthState => {
    return updateObject(state, {
        error: null
    })
}

const reducer = (state:IAuthState = initialState, action:IAuthAction):IAuthState => {
    switch (action.type) {
        case ActionTypes.AUTH_START : return authStart(state, action);
        case ActionTypes.AUTH_SUCCESS : return authSuccess(state, action);
        case ActionTypes.AUTH_FAIL : return authFail(state, action);
        case ActionTypes.LOGOUT_START : return logoutStart(state, action);
        case ActionTypes.LOGOUT_SUCCESS : return logoutSuccess(state, action);
        case ActionTypes.LOGOUT_FAIL : return logoutFail(state, action);
        case ActionTypes.GET_CURRENT_USER_START : return getCurrentUserStart(state, action);
        case ActionTypes.GET_CURRENT_USER_SUCCESS : return getCurrentUserSuccess(state, action);
        case ActionTypes.GET_CURRENT_USER_FAIL : return getCurrentUserFail(state, action);
        case ActionTypes.CLEAR_ERROR : return clearError(state, action);
        case ActionTypes.STORE_AUTH_REDIRECT: return storeAuthRedirect(state, action);
        default: return state;

    }
}

export default reducer;
import React from 'react';

const RoiToolkitLogo = require('../../assets/images/logo_40x60.png');

const classes = require('./Logo.css');

const logo = () => (
    <div className={classes.Logo}>
        <img src={RoiToolkitLogo} alt="Roi Toolkit" />
    </div>
);

export default logo;
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';

import { IProduct } from '../../../interfaces';
import { defaultChartSettings } from '../../../shared/charts';

interface IProps {
  products: IProduct[];
}

enum Keys {
  PAYBACK_PERIOD = "Payback Period",
}

interface IData {
  name: string;
  [Keys.PAYBACK_PERIOD]: number;
}

const ProjectChartsPaybackPeriod: React.FC<IProps> = ({ products }) => {
  if (!products) return null;

  const getData = (): Array<IData> => {
    const data: Array<IData> = [];
    products.forEach((product) => {
      const calculations = product.calculations;
      if (!calculations || !calculations.length) return;
      data.push({
        name: product.name,
        [Keys.PAYBACK_PERIOD]: calculations.find(
          (calculations) =>
            calculations.priceStep === product.priceStep - 1 &&
            calculations.amountStep === product.amountStep - 1
        ).roiMonths,
      });
    });
    return data;
  };

  return (
    <ResponsiveBar
      {...defaultChartSettings}
      margin={{
        ...defaultChartSettings.margin,
        left: 70,
        right: 160,
      }}
      data={getData()}
      keys={[Keys.PAYBACK_PERIOD]}
      indexBy="name"
      axisLeft={{
        ...defaultChartSettings.axisLeft,
        legend: "Payback Period",
        legendOffset: -60,
      }}
    />
  );
};

export default ProjectChartsPaybackPeriod;

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, FormGroup, Input } from "reactstrap";
import { UserRole } from "../../enums";
import AdminRender from "../../hoc/Authorization/AdminRender";
import { IProduct, IUser } from "../../interfaces";
import { controlsToFormGroups, getFormData, initForm, validateInput } from "../../shared/utility";
import ProductImage from "./ProductImage/ProductImage";



interface IProps {
  index: number;
  product: IProduct;
  canRemove: boolean;
  canCopy: boolean;
  currentUser: IUser;
  removed: (index: number, id: string) => void;
  changed: (index: number, product: any) => void;
  copy: (product: IProduct) => void;
}

const ProductEdit: React.FC<IProps> = ({
  index,
  product,
  canRemove,
  canCopy,
  currentUser,
  removed,
  changed,
  copy
}) => {
  const [costsTotal, setCostsTotal] = useState(0);
  const [basicControls, setBasicControls] = useState({
    controls: {
      name: {
        elementType: "input",
        elementConfig: {
          label: "Name",
          type: "text"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false,
        editRoles: [UserRole.ADMIN]
      },
      description: {
        elementType: "textarea",
        elementConfig: {
          label: "Description",
          type: "textarea"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      retailPrice: {
        elementType: "input",
        elementConfig: {
          label: "Sales price min (inc. VAT)",
          type: "number"
        },
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      retailPriceMax: {
        elementType: "input",
        elementConfig: {
          label: "Sales price max (inc. VAT)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      }
    },
    formIsValid: false
  });

  const [costsControls, setCostControls] = useState({
    controls: {
      costProduction: {
        elementType: "input",
        elementConfig: {
          label: "Production cost (VAT 0%)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      },
      costPackaging: {
        elementType: "input",
        elementConfig: {
          label: "Packaging cost (VAT 0%)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      },
      costOther: {
        elementType: "input",
        elementConfig: {
          label: "Other (fixed) cost (VAT 0%)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      }
    },
    formIsValid: false
  });

  const [investmentControls, setInvestmentControls] = useState({
    controls: {
      investmentRD: {
        elementType: "input",
        elementConfig: {
          label: "R&D investment",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      },
      investmentMachinery: {
        elementType: "input",
        elementConfig: {
          label: "Machinery",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      },
      investmentOther: {
        elementType: "input",
        elementConfig: {
          label: "Other investment",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true
      }
    },
    formIsValid: false
  });

  const [resultControls, setResultControls] = useState({
    controls: {
      /*
      valueProfile: {
        elementType: "input",
        elementConfig: {
          label: "Value profile",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      */
      preference1: {
        elementType: "input",
        elementConfig: {
          label: "Preference (1st)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      preference2: {
        elementType: "input",
        elementConfig: {
          label: "Preference (2nd)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      willingnessToPay1: {
        elementType: "input",
        elementConfig: {
          label: "Willingness-to-pay (1st)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      willingnessToPay2: {
        elementType: "input",
        elementConfig: {
          label: "Willingness-to-pay (2nd)",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      },
      likelihoodOfPurchase: {
        elementType: "input",
        elementConfig: {
          label: "Likelihood-of-purchase",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      }
    },
    formIsValid: false
  });


  const [totalValueScoreControls, setTotalValueScoreControls] = useState({
    controls: {
      totalValueScore: {
        elementType: "input",
        elementConfig: {
          label: "Total value score",
          type: "number"
        },
        value: "",
        validation: {
          required: false
        },
        valid: true,
        visibleRoles: [UserRole.ADMIN]
      }
    },
    formIsValid: false
  });

  const [attachment, setAttachment] = useState(null);
  const [valueProfileAttachment, setValueProfileAttachment] = useState(null);
  const [error, setError] = useState(null);
  const [copyAttachmentFrom, setCopyAttachmentFrom] = useState(null);
  const [
    copyValueProfileAttachmentFrom,
    setCopyValueProfileAttachmentFrom
  ] = useState(null);

  useEffect(() => {
    setBasicControls(initForm(basicControls.controls, product));
    setCostControls(initForm(costsControls.controls, product));
    setInvestmentControls(initForm(investmentControls.controls, product));
    setResultControls(initForm(resultControls.controls, product));
    setTotalValueScoreControls(initForm(totalValueScoreControls.controls, product));

    setAttachment(product.attachment);
    setValueProfileAttachment(product.valueProfileAttachment);
    setCopyAttachmentFrom(product.copyAttachmentFrom);
    setCopyValueProfileAttachmentFrom(product.copyValueProfileAttachmentFrom);
  }, []);


  useEffect(() => {
    updateProduct();
  }, [basicControls, costsControls, investmentControls, resultControls, attachment, valueProfileAttachment, copyAttachmentFrom, copyValueProfileAttachmentFrom, totalValueScoreControls])

  const updateProduct = () => {
    const product: any = {
      ...getFormData(basicControls.controls),
      ...getFormData(costsControls.controls),
      ...getFormData(investmentControls.controls),
      ...getFormData(resultControls.controls),
      ...getFormData(totalValueScoreControls.controls)
    };

    product.isValid =
      basicControls.formIsValid &&
      costsControls.formIsValid &&
      investmentControls.formIsValid &&
      resultControls.formIsValid &&
      totalValueScoreControls.formIsValid &&
      attachment !== null;
    product.attachment = attachment;
    product.valueProfileAttachment = valueProfileAttachment;
    product.copyAttachmentFrom = copyAttachmentFrom;
    product.copyValueProfileAttachmentFrom = copyValueProfileAttachmentFrom;
    changed(index, product);
    countCostsTotal(product);
  };


  const countCostsTotal = (product: IProduct) => {
    let total = 0;
    if(product.costProduction && !isNaN(product.costOther))  {
      total += +product.costProduction;
    }
    if(product.costPackaging && !isNaN(product.costOther))  {
      total += +product.costPackaging;
    }
    if(product.costOther && !isNaN(product.costOther))  {
      total += +product.costOther;
    }
    setCostsTotal(total);
  }

  const inputChangedHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    controlName: string,
    controls: any,
    setControls: (controls: any) => void
  ) => {
    const validation = validateInput(controls, controlName, event.target.value);

    setControls(validation);
  };

  const onDropzoneDropAttachment = (files: Array<File>) => {
    const file = files[0];
    if (file) {
      const attachment = Object.assign(file, {
        link: URL.createObjectURL(file)
      });

      setAttachment(attachment);
      setError(null);
    } else {
      setError("Cannot upload file, please make sure that file is image");
    }
  };

  const onDropzoneDropValueProfileAttachment = (files: Array<File>) => {
    const file = files[0];
    if (file) {
      const valueProfileAttachment = Object.assign(file, {
        link: URL.createObjectURL(file)
      });

      setValueProfileAttachment(valueProfileAttachment);
      setError(null);
    } else {
      setError("Cannot upload file, please make sure that file is image");
    }
  };

  const onRemoveAttachment = () => {
    setAttachment(null);
    setCopyAttachmentFrom(null);
  };

  const onRemoveValueProfileAttachment = () => {
    setValueProfileAttachment(null);
    setCopyValueProfileAttachmentFrom(null);
  };

  return (
    <Col xs="12" md="4" lg="3">
      <Card style={{ margin: "1rem 0" }}>
        <ProductImage
          attachment={attachment}
          imageActions={AdminRender(
            [
              {
                text: "Remove image",
                clicked: onRemoveAttachment,
                icon: faTrash
              }
            ],
            currentUser
          )}
          showDownload
          edit={{
            onDrop: onDropzoneDropAttachment
          }}
        />
        {error && <Alert color="danger">{error}</Alert>}
        <CardBody>
          {controlsToFormGroups(
            basicControls.controls,
            (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
              inputChangedHandler(
                event,
                controlName,
                basicControls.controls,
                setBasicControls
              ),
            currentUser
          )}
          <h5>Costs</h5>
          {controlsToFormGroups(
            costsControls.controls,
            (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
              inputChangedHandler(
                event,
                controlName,
                costsControls.controls,
                setCostControls
              ),
            currentUser
          )}
          <FormGroup>
            <label>Unit cost (VAT 0%)</label>
            <Input plaintext>{costsTotal.toFixed(2)}</Input>
          </FormGroup>
          <h5>Evaluation</h5>
          {controlsToFormGroups(
            resultControls.controls,
            (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
              inputChangedHandler(
                event,
                controlName,
                resultControls.controls,
                setResultControls
              ),
            currentUser
          )}
          {AdminRender(
            <ProductImage
              attachment={valueProfileAttachment}
              imageActions={[
                {
                  text: "Remove image",
                  clicked: onRemoveValueProfileAttachment,
                  icon: faTrash
                }
              ]}
              showDownload
              containerStyles={{
                marginBottom: "1rem",
                padding: "0",
                minHeight: "auto"
              }}
              styles={{ height: "10rem" }}
              edit={{
                onDrop: onDropzoneDropValueProfileAttachment
              }}
              label="Value profile score"
            />,
            currentUser
          )}

          {controlsToFormGroups(
            totalValueScoreControls.controls,
            (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
              inputChangedHandler(
                event,
                controlName,
                totalValueScoreControls.controls,
                setTotalValueScoreControls
              ),
            currentUser
          )}

          <h5>Investment</h5>
          {controlsToFormGroups(
            investmentControls.controls,
            (event: React.ChangeEvent<HTMLInputElement>, controlName: string) =>
              inputChangedHandler(
                event,
                controlName,
                investmentControls.controls,
                setInvestmentControls
              ),
            currentUser
          )}

          {canRemove && (
            <Button onClick={() => removed(index, product.id)}>Remove</Button>
          )}
          {canCopy && <Button onClick={() => copy(product)}>Copy</Button>}
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProductEdit;

export enum ActionTypes {
  STORE_AUTH_REDIRECT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERROR,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  FIND_USERS_START,
  FIND_USERS_SUCCESS,
  FIND_USERS_FAIL,
  SEARCH_USERS_START,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  SAVE_USER_START,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAIL,
  GET_ACTIVATE_USER_START,
  GET_ACTIVATE_USER_SUCCESS,
  GET_ACTIVATE_USER_FAIL,
  ACTIVATE_USER_START,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,
  CLEAR_PROJECTS_STATE,
  FIND_PROJECTS_START,
  FIND_PROJECTS_SUCCESS,
  FIND_PROJECTS_FAIL,
  GET_PROJECT_START,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  UPDATE_PROJECT_START,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  SAVE_PROJECT_START,
  SAVE_PROJECT_SUCCESS,
  SAVE_PROJECT_FAIL,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  DELETE_PROJECT_ATTACHMENT_START,
  DELETE_PROJECT_ATTACHMENT_SUCCESS,
  DELETE_PROJECT_ATTACHMENT_FAIL,
  SHARE_PROJECT_START,
  SHARE_PROJECT_SUCCESS,
  SHARE_PROJECT_FAIL,
  UNSHARE_PROJECT_START,
  UNSHARE_PROJECT_SUCCESS,
  UNSHARE_PROJECT_FAIL,
  SAVE_PROJECT_COMMENT_START,
  SAVE_PROJECT_COMMENT_SUCCESS,
  SAVE_PROJECT_COMMENT_FAIL,
  UPDATE_PROJECT_COMMENT_START,
  UPDATE_PROJECT_COMMENT_SUCCESS,
  UPDATE_PROJECT_COMMENT_FAIL,
  DELETE_PROJECT_COMMENT_START,
  DELETE_PROJECT_COMMENT_SUCCESS,
  DELETE_PROJECT_COMMENT_FAIL,
  SEARCH_PROJECTS_START,
  SEARCH_PROJECTS_SUCCESS,
  SEARCH_PROJECTS_FAIL,
}

/*
export const STORE_AUTH_REDIRECT,

export const AUTH_START,
export const AUTH_SUCCESS,
export const AUTH_FAIL,

export const LOGOUT_START,
export const LOGOUT_SUCCESS,
export const LOGOUT_FAIL,

export const CLEAR_ERROR,

export const GET_CURRENT_USER_START,
export const GET_CURRENT_USER_SUCCESS,
export const GET_CURRENT_USER_FAIL,

export const FIND_USERS_START,
export const FIND_USERS_SUCCESS,
export const FIND_USERS_FAIL,

export const SEARCH_USERS_START,
export const SEARCH_USERS_SUCCESS,
export const SEARCH_USERS_FAIL,

export const GET_USER_START,
export const GET_USER_SUCCESS,
export const GET_USER_FAIL,

export const UPDATE_USER_START,
export const UPDATE_USER_SUCCESS,
export const UPDATE_USER_FAIL,

export const SAVE_USER_START,
export const SAVE_USER_SUCCESS,
export const SAVE_USER_FAIL,

export const GET_ACTIVATE_USER_START,
export const GET_ACTIVATE_USER_SUCCESS,
export const GET_ACTIVATE_USER_FAIL,

export const ACTIVATE_USER_START,
export const ACTIVATE_USER_SUCCESS,
export const ACTIVATE_USER_FAIL,

export const CLEAR_PROJECTS_STATE,

export const FIND_PROJECTS_START,
export const FIND_PROJECTS_SUCCESS,
export const FIND_PROJECTS_FAIL,

export const GET_PROJECT_START,
export const GET_PROJECT_SUCCESS,
export const GET_PROJECT_FAIL,

export const UPDATE_PROJECT_START,
export const UPDATE_PROJECT_SUCCESS,
export const UPDATE_PROJECT_FAIL,

export const SAVE_PROJECT_START,
export const SAVE_PROJECT_SUCCESS,
export const SAVE_PROJECT_FAIL,

export const DELETE_PROJECT_START,
export const DELETE_PROJECT_SUCCESS,
export const DELETE_PROJECT_FAIL,

export const DELETE_PROJECT_ATTACHMENT_START,
export const DELETE_PROJECT_ATTACHMENT_SUCCESS,
export const DELETE_PROJECT_ATTACHMENT_FAIL,

export const SHARE_PROJECT_START,
export const SHARE_PROJECT_SUCCESS,
export const SHARE_PROJECT_FAIL,

export const UNSHARE_PROJECT_START,
export const UNSHARE_PROJECT_SUCCESS,
export const UNSHARE_PROJECT_FAIL,

export const SAVE_PROJECT_COMMENT_START,
export const SAVE_PROJECT_COMMENT_SUCCESS,
export const SAVE_PROJECT_COMMENT_FAIL,

export const UPDATE_PROJECT_COMMENT_START,
export const UPDATE_PROJECT_COMMENT_SUCCESS,
export const UPDATE_PROJECT_COMMENT_FAIL,

export const DELETE_PROJECT_COMMENT_START,
export const DELETE_PROJECT_COMMENT_SUCCESS,
export const DELETE_PROJECT_COMMENT_FAIL,

export const SEARCH_PROJECTS_START,
export const SEARCH_PROJECTS_SUCCESS,
export const SEARCH_PROJECTS_FAIL,
*/



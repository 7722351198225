import React from 'react';

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const classes = require('./ContainerActions.module.scss');

import { IContainerActions } from '../../../interfaces';

interface IProps {
    children: any,
    actions: Array<IContainerActions>
}


const containerActions = ({children, actions}:IProps) => {
    if(!actions) return children;


    let content = null;
    if(actions.length === 1 && actions[0].icon) {
        const action = actions[0];
        content = (
            <span onClick={action.clicked}>
                <FontAwesomeIcon className={classes.Toggle} icon={action.icon}  style={{padding: 0, marginRight: '0.25rem'}} />
            </span>
        );
    } else {
        const tag = <FontAwesomeIcon icon={faEllipsisV} />;
        content = (
            <UncontrolledDropdown>
                <DropdownToggle tag="span" className={classes.Toggle}>{tag}</DropdownToggle>                
                <DropdownMenu>
                    {actions.map((action, i) => (
                        <DropdownItem key={i} onClick={action.clicked} className={["unselectable", classes.Item].join(' ')}>
                            {action.icon ? <FontAwesomeIcon icon={action.icon} /> : null} {action.text}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }

    return (
        <div className={classes.Container}>
            {content}
            {children}
        </div>
    )
}

export default containerActions;
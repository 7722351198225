import 'react-circular-progressbar/dist/styles.css';

import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';


interface IProps {
    score: number,
    scoreDiff: number | string,
    hide: boolean
}

const scoreCircle = ({ score, scoreDiff, hide }:IProps) => {
    if(hide) return null;
    
    if(!score) return null;
    const negativeColor = 'red';

    let counterClockwise = false,
        circleColor = '',
        textColor = '',
        text = score.toString();

    if(score < 0) {
        counterClockwise = true;
        circleColor = negativeColor;
        textColor = negativeColor;
    }

    if(scoreDiff) {
        if(scoreDiff > 0) {
            scoreDiff = `+${scoreDiff}`;
        } else {
            textColor = negativeColor;
        }
        text += ` (${scoreDiff})`;
    }


    return (
        <>
            <h5 style={{ textAlign: 'center'}}>Total value score</h5>
            <div style={{paddingLeft: '2rem', paddingRight: '2rem'}}>
                <CircularProgressbar 
                    percentage={Math.abs(score)} 
                    text={text} 
                    counterClockwise={counterClockwise} 
                    styles={{
                        path: {
                            stroke: circleColor
                        },
                        text: {
                            fill: textColor
                        }
                    }} 
                />
            </div>
        </>
    );
};


export default scoreCircle;
import React, { Component } from "react";
import { Progress } from "reactstrap";

class ImageSpinner extends Component<any> {
  state = {
    loading: true,
  };

  onLoad = () => {
    this.setState({
      loading: false,
    });
  };

  onError = () => {
    console.log("onError");
  };

  render() {
    const Tag = this.props.tag;
    const { loading } = this.state;
    const styles = {
      ...this.props.style,
      display: loading ? "none" : "initial",
    };

    return (
      <React.Fragment>
        <Tag
          {...this.props}
          onLoad={this.onLoad}
          onError={this.onError}
          style={styles}
        />
        {loading ? <Progress value="100" animated color="primary" /> : null}
      </React.Fragment>
    );
  }
}

export default ImageSpinner;

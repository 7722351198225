import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import {IUserState, IUserAction} from '../../interfaces';

const initialState:IUserState = {
    users: null,
    loading: false,
    error: null,
    user: null,
    success: false,
    search: null
};

const findUsersStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const findUsersSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        users: action.users,
        error: null,
        loading: false,
        user: null
    });
}

const findUsersFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchUsersFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const searchUsersStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const searchUsersSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        search: action.search,
        error: null,
        loading: false
    });
}

const getUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const getUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        user: action.user,
        error: null,
        loading: false
    });
}

const getUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const saveUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const saveUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const updateUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const updateUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getActivateUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const getActivateUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        user: action.user,
        error: null,
        loading: false
    });
}

const getActivateUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const activateUserStart = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, { error: null, loading: true});
}

const activateUserSuccess = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const activateUserFail = (state:IUserState, action:IUserAction):IUserState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};





const reducer = (state:IUserState = initialState, action:IUserAction):IUserState => {
    switch (action.type) {
        case ActionTypes.FIND_USERS_START : return findUsersStart(state, action);
        case ActionTypes.FIND_USERS_SUCCESS : return findUsersSuccess(state, action);
        case ActionTypes.FIND_USERS_FAIL : return findUsersFail(state, action);
        case ActionTypes.SEARCH_USERS_START : return searchUsersStart(state, action);
        case ActionTypes.SEARCH_USERS_SUCCESS : return searchUsersSuccess(state, action);
        case ActionTypes.SEARCH_USERS_FAIL : return searchUsersFail(state, action);
        case ActionTypes.GET_USER_START : return getUserStart(state, action);
        case ActionTypes.GET_USER_SUCCESS : return getUserSuccess(state, action);
        case ActionTypes.GET_USER_FAIL : return getUserFail(state, action);
        case ActionTypes.SAVE_USER_START : return saveUserStart(state, action);
        case ActionTypes.SAVE_USER_SUCCESS : return saveUserSuccess(state, action);
        case ActionTypes.SAVE_USER_FAIL : return saveUserFail(state, action);
        case ActionTypes.UPDATE_USER_START : return updateUserStart(state, action);
        case ActionTypes.UPDATE_USER_SUCCESS : return updateUserSuccess(state, action);
        case ActionTypes.UPDATE_USER_FAIL : return updateUserFail(state, action);
        case ActionTypes.GET_ACTIVATE_USER_START : return getActivateUserStart(state, action);
        case ActionTypes.GET_ACTIVATE_USER_SUCCESS : return getActivateUserSuccess(state, action);
        case ActionTypes.GET_ACTIVATE_USER_FAIL : return getActivateUserFail(state, action);
        case ActionTypes.ACTIVATE_USER_START : return activateUserStart(state, action);
        case ActionTypes.ACTIVATE_USER_SUCCESS : return activateUserSuccess(state, action);
        case ActionTypes.ACTIVATE_USER_FAIL : return activateUserFail(state, action);
        default: return state;

    }
}

export default reducer;
import { ActionTypes } from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import { IProjectState, IProjectAction } from '../../interfaces';

const initialState:IProjectState = {
    projects: null,
    loading: false,
    error: null,
    project: null,
    success: false,
    shareErrors: null,
    cursor: '',
    hasMore: false,
    commentsLoading: false,
    commentsError: null,
    search: null
};

const clearProjectsState = (state:IProjectState, action:IProjectAction):IProjectState => {
    return initialState;
}

const searchProjectsFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error
    });
};

const searchProjectsStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { 
        error: null
    });
}

const searchProjectsSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        search: action.search,
        error: null
    });
}

const findProjectsStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { error: null, loading: true, success: false, hasMore: false});
}

const findProjectsSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    let projects = state.projects || [];
    if(action.projects) {
        projects = [...projects, ...action.projects];
    }

    return updateObject(state, {
        projects,
        cursor: action.cursor,
        hasMore: action.hasMore,
        error: null,
        loading: false,
        project: null,
    });
}

const findProjectsFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const getProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { 
        error: null, 
        loading: true, 
        success: false,
        project: null
    });
}

const getProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        project: action.project,
        error: null,
        loading: false
    });
}

const getProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { 
        error: null, 
        loading: true, 
        success: false,
        project: null
    });
}

const deleteProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        loading: false,
        success: true
    });
}

const deleteProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const deleteProjectAttachmentStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { 
        error: null, 
        loading: true
    });
}

const deleteProjectAttachmentSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        loading: false
    });
}

const deleteProjectAttachmentFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { error: null, loading: true });
}

const saveProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false,
        project: action.project
    });
}

const saveProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const updateProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { error: null, loading: true});
}

const updateProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        success: true,
        loading: false
    });
}

const updateProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const shareProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { error: null, loading: true, shareErrors: null });
}

const shareProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        loading: false,
        project: action.project,
        shareErrors: action.shareErrors
    });
}

const shareProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        shareErrors: action.shareErrors
    });
};

const unshareProjectStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { error: null, loading: true});
}

const unshareProjectSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: null,
        loading: false,
        project: action.project,
    });
}

const unshareProjectFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const saveProjectCommentStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { commentsError: null, commentsLoading: true });
}

const saveProjectCommentSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: null,
        commentsLoading: false,
        project: action.project,
    });
}

const saveProjectCommentFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: action.error,
        commentsLoading: false
    });
};

const updateProjectCommentStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { commentsError: null, commentsLoading: true});
}

const updateProjectCommentSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: null,
        commentsLoading: false,
        project: action.project,
    });
}

const updateProjectCommentFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: action.error,
        commentsLoading: false
    });
};

const deleteProjectCommentStart = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, { commentsError: null, commentsLoading: true});
}

const deleteProjectCommentSuccess = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: null,
        commentsLoading: false,
        project: action.project,
    });
}

const deleteProjectCommentFail = (state:IProjectState, action:IProjectAction):IProjectState => {
    return updateObject(state, {
        commentsError: action.error,
        commentsLoading: false
    });
};



const reducer = (state:IProjectState = initialState, action:IProjectAction):IProjectState => {
    switch (action.type) {
        case ActionTypes.CLEAR_PROJECTS_STATE : return clearProjectsState(state, action);
        case ActionTypes.FIND_PROJECTS_START : return findProjectsStart(state, action);
        case ActionTypes.FIND_PROJECTS_SUCCESS : return findProjectsSuccess(state, action);
        case ActionTypes.FIND_PROJECTS_FAIL : return findProjectsFail(state, action);
        case ActionTypes.GET_PROJECT_START : return getProjectStart(state, action);
        case ActionTypes.GET_PROJECT_SUCCESS : return getProjectSuccess(state, action);
        case ActionTypes.GET_PROJECT_FAIL : return getProjectFail(state, action);
        case ActionTypes.DELETE_PROJECT_START : return deleteProjectStart(state, action);
        case ActionTypes.DELETE_PROJECT_SUCCESS : return deleteProjectSuccess(state, action);
        case ActionTypes.DELETE_PROJECT_FAIL : return deleteProjectFail(state, action);
        case ActionTypes.DELETE_PROJECT_ATTACHMENT_START : return deleteProjectAttachmentStart(state, action);
        case ActionTypes.DELETE_PROJECT_ATTACHMENT_SUCCESS : return deleteProjectAttachmentSuccess(state, action);
        case ActionTypes.DELETE_PROJECT_ATTACHMENT_FAIL : return deleteProjectAttachmentFail(state, action);
        case ActionTypes.SAVE_PROJECT_START : return saveProjectStart(state, action);
        case ActionTypes.SAVE_PROJECT_SUCCESS : return saveProjectSuccess(state, action);
        case ActionTypes.SAVE_PROJECT_FAIL : return saveProjectFail(state, action);
        case ActionTypes.UPDATE_PROJECT_START : return updateProjectStart(state, action);
        case ActionTypes.UPDATE_PROJECT_SUCCESS : return updateProjectSuccess(state, action);
        case ActionTypes.UPDATE_PROJECT_FAIL : return updateProjectFail(state, action);
        case ActionTypes.SHARE_PROJECT_START : return shareProjectStart(state, action);
        case ActionTypes.SHARE_PROJECT_SUCCESS : return shareProjectSuccess(state, action);
        case ActionTypes.SHARE_PROJECT_FAIL : return shareProjectFail(state, action);
        case ActionTypes.UNSHARE_PROJECT_START : return unshareProjectStart(state, action);
        case ActionTypes.UNSHARE_PROJECT_SUCCESS : return unshareProjectSuccess(state, action);
        case ActionTypes.UNSHARE_PROJECT_FAIL : return unshareProjectFail(state, action);
        case ActionTypes.SAVE_PROJECT_COMMENT_START : return saveProjectCommentStart(state, action);
        case ActionTypes.SAVE_PROJECT_COMMENT_SUCCESS : return saveProjectCommentSuccess(state, action);
        case ActionTypes.SAVE_PROJECT_COMMENT_FAIL : return saveProjectCommentFail(state, action);
        case ActionTypes.UPDATE_PROJECT_COMMENT_START : return updateProjectCommentStart(state, action);
        case ActionTypes.UPDATE_PROJECT_COMMENT_SUCCESS : return updateProjectCommentSuccess(state, action);
        case ActionTypes.UPDATE_PROJECT_COMMENT_FAIL : return updateProjectCommentFail(state, action);
        case ActionTypes.DELETE_PROJECT_COMMENT_START : return deleteProjectCommentStart(state, action);
        case ActionTypes.DELETE_PROJECT_COMMENT_SUCCESS : return deleteProjectCommentSuccess(state, action);
        case ActionTypes.DELETE_PROJECT_COMMENT_FAIL : return deleteProjectCommentFail(state, action);
        case ActionTypes.SEARCH_PROJECTS_START : return searchProjectsStart(state, action);
        case ActionTypes.SEARCH_PROJECTS_SUCCESS : return searchProjectsSuccess(state, action);
        case ActionTypes.SEARCH_PROJECTS_FAIL : return searchProjectsFail(state, action);
        default: return state;

    }
}

export default reducer;
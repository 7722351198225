import 'react-quill/dist/quill.snow.css';

import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { Button } from 'reactstrap';
import { IComment } from '../../../interfaces';

interface IProps {
    comment?: IComment,
    loading?: boolean,
    onSave?: (value:string) => void,
    onUpdate?: (value:string, id:string) => void,
    onCancel?: () => void
}

class CommentsEditor extends Component<IProps> {
    state = {
        value: '',
    }

    componentDidMount() {
        const { comment } = this.props;

        if(comment && comment.value) {
            this.setState({
                value: comment.value
            })
        }
    }

    onChange = (value:string, delta:any, source:string) => {
        if(source === 'api') return;

        this.setState({
            value
        });
    }

    onSubmit = () => {
        const { onSave, onUpdate, comment } = this.props;

        if(onSave) {
            this.props.onSave(this.state.value);
        } else if (onUpdate && comment) {
            this.props.onUpdate(comment.id, this.state.value)
        } else {
            alert('Something went wrong.'); // Coding error never should be like this.
        }

        this.setState({
            value: ''
        });
        
    }

    render() {
        const { onCancel } = this.props;

        let cancelButton = null;
        if(onCancel) {
            cancelButton = (
                <Button
                    color="secondary"
                    onClick={onCancel}>
                    Cancel    
                </Button>
            );
        }

        return (
            <React.Fragment>
                <ReactQuill 
                    onChange={this.onChange} 
                    value={this.state.value} 
                    style={{ marginBottom: '1rem' }} 
                />
                <Button 
                    color="primary" 
                    onClick={this.onSubmit} 
                    disabled={this.state.value.length === 0 || this.props.loading}
                >   
                    Submit
                </Button> {cancelButton}
            </React.Fragment>
        )
    }
    
}


export default CommentsEditor;
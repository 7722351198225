import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter
} from "react-router-dom";

import Activate from "./containers/Activate/Activate";
import Auth from "./containers/Auth/Auth";
import Logout from "./containers/Auth/Logout/Logout";
import ProductDetails from "./containers/Projects/Project/Product/ProductDetails";
import ProjectEdit from "./containers/Projects/Project/ProjectEdit";
import ProjectView from "./containers/Projects/Project/ProjectView";
import Projects from "./containers/Projects/Projects";
import User from "./containers/Users/User/User";
import Users from "./containers/Users/Users";
import AdminAuthorization from "./hoc/Authorization/AdminAuthorization";
import Layout from "./hoc/Layout/Layout";
import { IAppState } from "./interfaces";
import * as actions from "./store/actions";

interface IDispatchProps {
  onGetCurrentUser: () => {};
  onStoreAuthRedirect: (redirectUrl: string) => {};
}

interface IStateProps {
  isAuthenticated: boolean;
  redirect: string;
}

interface IProps extends IDispatchProps, IStateProps, RouteComponentProps {}

const App: React.FC<IProps> = props => {
  useEffect(() => {
    const email = localStorage.getItem("email");
    const accessToken = localStorage.getItem("accessToken");
    const redirectUrl = props.location.pathname;
    if (email && accessToken) {
      props.onGetCurrentUser();
      props.onStoreAuthRedirect(redirectUrl);
    }
  }, [props.onGetCurrentUser]);

  useEffect(() => {
    if (
      props.isAuthenticated &&
      props.redirect &&
      props.redirect !== props.location.pathname
    ) {
      props.history.replace(props.redirect);
    }
  }, [props.isAuthenticated]);

  /*
  async componentDidMount() {
    const email = localStorage.getItem('email');
    const accessToken = localStorage.getItem('accessToken');
    
    const redirectUrl = this.props.location.pathname;
    console.log(redirectUrl)
    if(email && accessToken) {
      await this.props.onGetCurrentUser();
      console.log("hep");
      if(this.props.isAuthenticated) {
        console.log("hap");
        this.props.history.replace(redirectUrl);
        return;
      }
    }
    if(redirectUrl !== '/') {
      this.props.onStoreAuthRedirect(redirectUrl);
    }
  }

  shouldComponentUpdate(nextProps:IStateProps) {
    return this.props.isAuthenticated === nextProps.isAuthenticated;
  }
  */

  let app = (
    <Switch>
      <Route path="/" exact component={Auth} />
      <Route path="/activate/:id/:activateToken" component={Activate} />
      <Redirect to="/" />
    </Switch>
  );

  if (props.isAuthenticated) {
    app = (
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route
          path="/projects/:id/product/:productId"
          component={ProductDetails}
        />
        <Route
          path="/projects/:id/edit"
          component={ProjectEdit}
        />
        <Route
          path="/projects/add"
          component={AdminAuthorization(ProjectEdit)}
        />
        <Route path="/projects/:id" component={ProjectView} />
        <Route path="/projects" component={Projects} />
        <Route path="/users/:id" component={User} />
        <Route path="/users" component={AdminAuthorization(Users)} />
        <Redirect to="/projects" />
      </Switch>
    );
  }

  return <Layout>{app}</Layout>;
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    isAuthenticated: state.auth.currentUser !== null,
    redirect: state.auth.redirect
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
    onStoreAuthRedirect: redirectUrl =>
      dispatch(actions.storeAuthRedirect(redirectUrl))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);

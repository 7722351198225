import { BarSvgProps } from "@nivo/bar";
import { start } from "repl";

const themeText = {
  text: {
    fontSize: "1rem"
  }
};

export const defaultChartSettings:Partial<BarSvgProps> = {
  margin: { top: 50, right: 130, bottom: 30, left: 60 },
  groupMode: "grouped",
  colors: ["#4f83cc", "#5eb8ff", "#67daff", "#8bf6ff"],
  theme: {
    legends: themeText,
    labels: themeText,
    axis: {
      legend: themeText,
      ticks: themeText
    }
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendPosition: "middle",
    legendOffset: -40
  },
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
  legends: [
    {
      dataFrom: "keys",
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: "left-to-right",
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1
          }
        }
      ]
    }
  ],
  animate: true,
  motionStiffness: 90,
  motionDamping: 15
};

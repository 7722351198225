import "rc-slider/assets/index.css";

import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Slider from "rc-slider";
import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import { Alert, Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";

import Attachments from "../../../components/Attachments/Attachments";
import ProductView from "../../../components/Product/ProductView";
import Share from "../../../components/Share/Share";
import Comments from "../../../components/UI/Comments/Comments";
import ConfirmationDialog from "../../../components/UI/ConfirmationDialog/ConfirmationDialog";
import AdminRender from "../../../hoc/Authorization/AdminRender";
import ContentBox from "../../../hoc/ContentBox/ContentBox";
import {
  IAppState,
  IDialog,
  IProject,
  IUser,
  IProduct
} from "../../../interfaces";
import * as actions from "../../../store/actions";
import ProjectSliders from "../../../components/ProjectSliders/ProjectSliders";
import { getObjectValue } from "../../../shared/utility";
import ProjectCharts from "../../../components/ProjectCharts/ProjectCharts";
import ProductScoresContext from "../../../components/Product/ProductScores/ProductScoresContext";

const classes = require("./ProjectView.module.scss");

interface IStateProps {
  currentUser: IUser;
  project: IProject;
  loading: boolean;
  error: string;
  commentsLoading: boolean;
  commentsError: string;
}

interface IDispatchProps {
  onGetProject: (id: string) => void;
  onSaveProjectComment: (projectId: string, formData: any) => void;
  onDeleteProjectComment: (projectId: string, id: any) => void;
  onUpdateProjectComment: (
    projectId: string,
    id: string,
    formData: any
  ) => void;
}

interface IMatchProps {
  id: string;
}

interface IProps
  extends IStateProps,
    IDispatchProps,
    RouteComponentProps<IMatchProps> {}

const ProjectView: React.FC<IProps> = ({
  currentUser,
  project,
  loading,
  error,
  commentsLoading,
  commentsError,
  onGetProject,
  onSaveProjectComment,
  onDeleteProjectComment,
  onUpdateProjectComment,
  match,
  history
}) => {
  const [showProductScores, setShowProductScores] = useState(false);
  const [projectProducts, setProjectProducts] = useState<IProduct[]>(null);
  const id = match.params.id;

  //const [amountStep, setAmountStep] = useState(1);
  //const [priceStep, setPriceStep] = useState(1);
  const [dialog, setDialog] = useState<IDialog>({
    isOpen: false
  });

  useEffect(() => {
    if (id) {
      onGetProject(id);
    }

    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    let products = null;
    if (project) {
      products = project.products.map(product => {
        return {
          ...product,
          amountStep: 1,
          priceStep: 1
        };
      });
    }
    setProjectProducts(products);
  }, [project]);

  const productDetailsHandler = (id: string) => {
    history.push(`/projects/${project.id}/product/${id}`);
  };

  const commentsSaveHandler = (value: string) => {
    onSaveProjectComment(project.id, { value });
  };

  const commentsUpdateHandler = async (id: string, value: string) => {
    return await onUpdateProjectComment(project.id, id, { value });
  };

  const commentsDeleteHandler = async (id: string) => {
    setDialog({
      isOpen: true,
      title: "Delete note",
      text: "Are you sure you wan't to delete note?",
      loadingField: "commentsLoading",
      onOk: async () => {
        await onDeleteProjectComment(project.id, id);
        setDialog({
          isOpen: false
        });
      }
    });
  };

  const updateAmountStepHandler = (id: string, step: number) => {
    const newProducts = [...projectProducts];
    newProducts.find(product => product.id === id).amountStep = step;
    setProjectProducts(newProducts);
  };

  const updatePriceStepHandler = (id: string, step: number) => {
    const newProducts = [...projectProducts];
    newProducts.find(product => product.id === id).priceStep = step;
    setProjectProducts(newProducts);
  };

  const renderProducts = () => {
    return (
      project && projectProducts &&
      projectProducts.map((product, index) => (
        <ProductView
          key={product.id}
          vat={project.vat}
          investmentPeriod={project.investmentPeriod}
          salesSlider={project.salesSlider}
          product={product}
          onUpdateAmountStep={updateAmountStepHandler}
          onUpdatePriceStep={updatePriceStepHandler}
          hideSliders={index === 0}
          // buttons={[{onClick: () => this.productDetailsHandler(product.id), text: 'Details'}]}
          // amountStep={amountStep}
          // priceStep={priceStep}
        />
      ))
    );
  };

  const errorMessage = error ? <Alert color="danger">{error}</Alert> : null;

  let title = null;
  let projectName = null;
  let contentActions = null;
  let minSalesAmount = 0;
  let maxSalesAmount = 0;
  let descriptionEl = null;
  let customActions = null;
  let vat = 0;
  let comments = null;
  const details: any = [];
  if (project) {
    title = `${project.name}`;
    projectName = project.name;
    vat = project.vat;
    contentActions = [{ icon: faEdit, link: `/projects/${project.id}/edit` }];
    minSalesAmount = project.minSalesAmount;
    maxSalesAmount = project.maxSalesAmount;
    descriptionEl = <p className="pre">{project.description || ""}</p>;

    if (project.projectDateString)
      details.push({ label: "Date", value: project.projectDateString });
    if (project.projectName)
      details.push({ label: "Project name", value: project.projectName });
    if (project.productName)
      details.push({ label: "Case product", value: project.productName });
    if (project.retailPrice)
      details.push({ label: "Retail price", value: project.retailPrice });
    if (project.dataInfo)
      details.push({ label: "Sample size", value: project.dataInfo });
    if (project.dataGender)
      details.push({ label: "Gender", value: project.dataGender });
    if (project.dataAge)
      details.push({ label: "Average age (range)", value: project.dataAge });
    if (project.productUse)
      details.push({
        label: "Product use",
        value: project.productUse,
        classNames: ["pre"]
      });
    if (project.otherCriteria)
      details.push({
        label: "Other criteria",
        value: project.otherCriteria,
        classNames: ["pre"]
      });
    if (project.aim)
      details.push({ label: "Aim", value: project.aim, classNames: ["pre"] });
    if (project.researchTask)
      details.push({
        label: "Research task",
        value: project.researchTask,
        classNames: ["pre"]
      });

    customActions = [<Share project={project} />];

    comments = project.comments;
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={dialog.isOpen}
        onOk={dialog.onOk}
        onCancel={() => setDialog({ isOpen: false })}
        title={dialog.title}
        loading={commentsLoading}
      >
        {dialog.text}
      </ConfirmationDialog>
      {errorMessage}
      <Breadcrumb>
        <BreadcrumbItem>
          <NavLink to="/projects">Projects</NavLink>
        </BreadcrumbItem>
        <BreadcrumbItem active>{projectName}</BreadcrumbItem>
      </Breadcrumb>
      <ContentBox
        size="h5"
        title={title}
        loading={loading}
        actions={contentActions}
        customActions={AdminRender(customActions, currentUser)}
      >
        {descriptionEl}
        {details.length > 0
          ? details.map((item: any, i: number) => {
              let classNames = ["mb-0"];
              if (item.classNames) {
                classNames = classNames.concat(item.classNames);
              }
              // check if contains line break then start from another line
              if (/\r|\n/.exec(item.value)) {
                item.value = "\n" + item.value;
              }
              return (
                <p key={i} className={classNames.join(" ")}>
                  <b>{item.label}:</b> {item.value}
                </p>
              );
            })
          : null}

        <div>
          {/*
            <ProjectSliders
            minSalesAmount={minSalesAmount}
            maxSalesAmount={maxSalesAmount}
            onAmountSliderChange={setAmountStep}
            onPriceSliderChange={setPriceStep}
            vat={vat}
          />
            
          */}
          <ProductScoresContext.Provider value={{ setShowProductScores, showProductScores}}>
            <Row>{renderProducts()}</Row>
          </ProductScoresContext.Provider>
        </div>

        <ProjectCharts
          products={projectProducts}
          onUpdateAmountStep={updateAmountStepHandler}
          onUpdatePriceStep={updatePriceStepHandler}
          salesSlider={project && project.salesSlider}
        />

        {project && project.attachments && (
          <Attachments attachments={project.attachments} />
        )}

        <Comments
          onSave={commentsSaveHandler}
          onUpdate={commentsUpdateHandler}
          onDelete={commentsDeleteHandler}
          comments={comments}
          loading={commentsLoading}
          error={commentsError}
          currentUser={currentUser}
        />
      </ContentBox>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    currentUser: state.auth.currentUser,
    project: state.projects.project,
    loading: state.projects.loading,
    error: state.projects.error,
    commentsLoading: state.projects.commentsLoading,
    commentsError: state.projects.commentsError
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onGetProject: id => dispatch(actions.getProject(id)),
    onSaveProjectComment: (projectId, formData) =>
      dispatch(actions.saveProjectComment(projectId, formData)),
    onDeleteProjectComment: (projectId, id) =>
      dispatch(actions.deleteProjectComment(projectId, id)),
    onUpdateProjectComment: (projectId, id, formData) =>
      dispatch(actions.updateProjectComment(projectId, id, formData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
